function Helper() {};

var promiseAudio;
Helper.prototype.play = function (file) {
  var self = this;
  console.log('play media:', file);
  if (!self.__audioTag) {
    self.__audioTag = new window.Audio(file);
  }
  self.__audioTag.src = file;
  promiseAudio = self.__audioTag.play();
  self.__audioTag.loop = true;
};

Helper.prototype.stop = function () {
  var self = this;
  console.log('stop', self.__audioTag);
  if (self.__audioTag) {
    if (promiseAudio) {
      promiseAudio
        .then(() => {
          self.__audioTag.pause();
          self.__audioTag.src = '';
        })
        .catch((err) => {
          console.error(err);
          self.__audioTag.pause();
          self.__audioTag.src = '';
        })
    }
  }
};

Helper.prototype.addStreamToMedia = function (stream, media) {
  console.log('attach media stream:', stream, media, this)
  if (!media || !stream) return;
  if (!navigator.mozGetUserMedia) media.srcObject = stream;
  else media.mozSrcObject = stream;
  media.play();
};

export default Helper;
