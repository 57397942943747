import { log } from './utils';
import { register } from '../../services/call';

export default function handleDispatchRegistration(state, action) {
  var message = {
    id: state.voiceLogs.length
  };
  if (action.value === 'registered') {
    state.voiceState = 'registered';
    message.value = log('Your device is registered');
    state.firstRegister = true;
  } else if (action.value === 'unregistered') {
    if (state.firstRegister) {
      state.voiceState = 'unregistered';
      message.value = log('Your device is unregistered');
      /* auto register */
      register();
    }
  } else if (action.value === 'failed') {
    state.voiceState = 'failed';
    message.value = log('Your device register failed');
  } else {
    message.value = log(`Unhandled REGISTRATION: ${action.value}`);
  }

  return { ...state, voiceLogs: [...state.voiceLogs, message] };
}
