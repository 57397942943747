import React from 'react';
import KEYPADS from '../../../../constant/javascript/keypads';
import { useCallMenuDispatch, useCallMenuState } from '../../../../context/CallMenuContext';
import { dtmf } from '../../../../services/call';
import _ from 'lodash';

function Button({ keypad, numkey, ...props }) {
  const state = useCallMenuState();
  const dispatch = useCallMenuDispatch();
  const classes = `__dig ${numkey}`;
  const { menuType } = props;

  const parseKeyString = (keyString) => {
    if (!_.size(keyString)) return keyString;
    var number = _.last(keyString.split('num'));
    return number;
  }

  const pressNumber = (keyString, key) => {
    if (menuType === 'primary') {
      dispatch({ type: 'SET_PHONE', number: state.phoneNumber + key });
    } else if (menuType === 'dtmf') {
      dtmf(key);
      dispatch({ type: 'SET_DTMF', number: state.dtmfNumber + key });
    }
    if (props.pressAnimate) props.pressAnimate(keyString);
    if (props.pressSound) { props.pressSound(parseKeyString(keyString)); }
    if (props.focusToInput) props.focusToInput();
  };

  return (
    <div
      className={classes}
      onClick={() => pressNumber(numkey, keypad[0])}
    >
      <span> {keypad[0]} </span>
      <small>{keypad[1]} </small>
    </div>
  );
}

export default function DigitsMenu({ className = '', ...props }) {
  return (
    <div className={className}>
      <Button numkey="num1" keypad={KEYPADS.num1} {...props} />
      <Button numkey="num2" keypad={KEYPADS.num2} {...props} />
      <Button numkey="num3" keypad={KEYPADS.num3} {...props} />
      <Button numkey="num4" keypad={KEYPADS.num4} {...props} />
      <Button numkey="num5" keypad={KEYPADS.num5} {...props} />
      <Button numkey="num6" keypad={KEYPADS.num6} {...props} />
      <Button numkey="num7" keypad={KEYPADS.num7} {...props} />
      <Button numkey="num8" keypad={KEYPADS.num8} {...props} />
      <Button numkey="num9" keypad={KEYPADS.num9} {...props} />
      <Button numkey="numasterist" keypad={KEYPADS.numasterist} {...props} />
      <Button numkey="num0" keypad={KEYPADS.num0} {...props} />
      <Button numkey="numhash" keypad={KEYPADS.numhash} {...props} />
    </div>
  )
}
