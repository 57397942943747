import React, { useEffect } from 'react';
import Dashboard from '../../pages/dashboard';
import { Route, Switch } from 'react-router-dom';
import { useUserDispatch } from '../../context/UserContext';
import NotificationService from '../../services/notification';

const Layout = () => {
  const userd = useUserDispatch();

  useEffect(() => {
    userd({ type: 'REFRESH_CUR_DOMAIN' });
    NotificationService.requestPermission();
    return () => console.log('Unmount Layout');
  }, [userd]);

  return (
    <div>
      <>
        <Switch>
          <Route path="/app" component={Dashboard} />
        </Switch>
      </>
    </div>
  );
};

export default Layout;
