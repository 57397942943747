/* global _ */
import React, { useState, useEffect } from 'react';
import validator from 'validator';
import { useUserState, useUserDispatch } from '../../context/UserContext';
import API from '../../apis';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';

const AliceTrialScreen = ({ history }) => {
  const { org, user } = useUserState();
  const userd = useUserDispatch();
  const limitPhoneNumbers = 5;
  const [speechContent, setSpeechContent] = useState('Chào anh chị, đây là cuộc gọi tự động từ hệ thống gọi tự động của chúng tôi. Rất cảm ơn anh chị đã quan tâm và trải nghiệm dịch vụ của chúng tôi');
  const [listNumbers, setListNumbers] = useState([]);
  const [numberToAdd, setNumberToAdd] = useState('');
  const [isNumberInvalid, setIsNumberInvalid] = useState(false);
  const [isContentInvalid, setIsContentInvalid] = useState(false);
  const [isCreatingTrialCampaign, setIsCreatingTrialCampaign] = useState(false);
  const [isStartingTrialCampaign, setIsStartingTrialCampaign] = useState(false);

  const removeNumber = (number) => {
    const cloneListNumbers = listNumbers.slice();
    _.remove(cloneListNumbers, (lnumber) => lnumber === number);
    setListNumbers(cloneListNumbers);
  };

  const tripNotNumber = (value) => {
    value = value.replace(/[^0-9*#+]/g, '');
    return value;
  };

  const changeNumberValue = (e) => {
    var value = tripNotNumber(e.target.value);
    setNumberToAdd(value);
    if (!/((09|03|07|08|05)+([0-9]{8})\b)/g.test(value)) {
      setIsNumberInvalid(true);
    } else {
      setIsNumberInvalid(false);
    }
  }

  const changeContentValue = (e) => {
    setSpeechContent(e.target.value);
    var value = e.target.value.trim();
    if (!validator.isLength(value, { min: 10, max: 256 })) setIsContentInvalid(true);
    else setIsContentInvalid(false);
  };

  const addNumber = () => {
    const cloneListNumbers = listNumbers.slice();
    const number = numberToAdd;
    if (cloneListNumbers.length >= 5) return;
    if (!number) return;
    if (!_.includes(cloneListNumbers, number)) {
      cloneListNumbers.push(number);
    } else {
      toast.warn('Số điện thoại đã có trong danh sách !', {
        autoClose: true,
        hideProgressBar: true,
        position: toast.POSITION.TOP_CENTER
      });
    }
    setListNumbers(cloneListNumbers);
    setNumberToAdd('');
  };

  const showCreateTrial = () => {
    return !_.get(org, 'metadata.initialize.create_trial_alice');
  };

  const showStartTrial = () => {
    return _.get(org, 'metadata.initialize.create_trial_alice');
  };

  // Tao campaign
  const createTrialCampaign = async () => {
    const payload = {
      script: speechContent,
      contacts: listNumbers.map(number => ({ contact: number }))
    };
    setIsCreatingTrialCampaign(true);
    try {
      const response = await API.Alice.createTrialCampaign(org.domain, payload);
      const newOrg = org;
      _.set(newOrg, 'metadata.initialize.create_trial_alice', true);
      _.set(newOrg, 'metadata.initialize.trial_campaign_id', response.data.campaignId);

      const NotifyCampaignCreated = () => {
        return (
          <div>
            <div className="font-weight-bold">Tạo chiến dịch thành công! </div>
            <div className="my-1">Khi nào bạn sẵn sàng, hãy nhấn</div>
            <div className="text-center font-weight-bold">Bắt Đầu Gọi</div>
            <div className="my-1">để bắt đầu chiến dịch</div>
          </div>
        );
      }
      toast.info(<NotifyCampaignCreated />, {
        autoClose: 8000,
        hideProgressBar: true,
        position: toast.POSITION.TOP_CENTER
      });
      userd({ type: 'SET', value: { org: newOrg } });
    } catch (e) {
      toast.error('Có lỗi khi tạo chiến dịch alice. Vui lòng thử lại sau hoặc liên hệ với support@antbuddy.com để được hỗ trợ', {
        autoClose: true,
        hideProgressBar: true,
        position: toast.POSITION.TOP_CENTER
      })
    }
    setIsCreatingTrialCampaign(false);
  };

  // Bat dau goi
  const startTrialCampaign = async () => {
    setIsStartingTrialCampaign(true);
    try {
      const campaignId = _.get(org, 'metadata.initialize.trial_campaign_id');
      await API.Alice.startTrialCampaign(org.domain, campaignId);
    } catch (e) {
      toast.error('Có lỗi khi bắt đầu cuộc gọi tự động. Vui lòng thử lại sau hoặc liên hệ với support@antbuddy.com để được hỗ trợ', {
        autoClose: true,
        hideProgressBar: true,
        position: toast.POSITION.TOP_CENTER
      })
    }
    setIsStartingTrialCampaign(false);
    const NotifyStartComponent = () => {
      return (
        <div>
          Chiến dịch <b>gọi tự động</b> đã bắt đầu !! <br />
          Hệ thống sẽ thực hiện cuộc gọi tự động đến danh sách mà bạn thiết lập trong vài phút tới. <br />
          Vui lòng nhấc máy để nghe nôi dung cuộc gọi. <br />
          Để sử dụng những tính năng khác của gọi tự động. Bạn có thể tiếp tục tại <b>Menu <i className="fa fa-caret-right" /> Alice Assistant</b>
        </div>
      )
    }
    toast.info(<NotifyStartComponent />, {
      autoClose: 30000,
      hideProgressBar: true,
      position: toast.POSITION.TOP_CENTER
    });

    const newOrg = org;
    _.set(newOrg, 'metadata.initialize.start_trial_alice', true);
    userd({ type: 'SET', value: { org: newOrg } });
    history.push('/app/dashboard');
  };

  const disallowToAddNumber = () => {
    return !numberToAdd || isNumberInvalid || listNumbers.length >= limitPhoneNumbers || showStartTrial();
  };

  useEffect(() => {
    if (user && user.phone) {
      setListNumbers([user.phone]);
    }
  }, [user]);

  return (
    <div className="alice-trial-screen-wrapper">
      <div className="form-group">
        <div className="font-weight-bold margin-bottom-5">
          Nội dung gọi tự động
        </div>
        <div className="alice-phone-description margin-bottom-10 small text-black-50">
          <i className="fa fa-exclamation-circle" /> Nội dung mà hệ thống sẽ đọc khi gọi đến danh sách người nhận
        </div>
        <textarea spellCheck="false" className={'form-control ' + (isContentInvalid ? 'is-invalid' : '')} placeholder="Nội dung cuộc gọi tự động" onChange={(e) => changeContentValue(e)} value={speechContent} disabled={showStartTrial()} />
        {isContentInvalid && <div className="invalid-feedback">Nội dung phải chứa ít nhất 10 ký tự, và tối đa 256 ký tự</div>}
      </div>

      <div className="form-group">
        <div className="font-weight-bold margin-bottom-5">
          Danh sách số điện thoại nhận cuộc gọi ({listNumbers.length}/{limitPhoneNumbers})
        </div>
        <div className="alice-phone-description margin-bottom-10 small text-black-50">
          <i className="fa fa-exclamation-circle" /> Số điện thoại nhận cuộc gọi là số di động, sử dụng nhà mạng từ Việt Nam
        </div>
        <div className="antbuddy-form-group">
          <div className="w-80">
            <input
              type="text"
              className={'antbuddy-input form-control ' + ((isNumberInvalid && numberToAdd && numberToAdd.length >= 10) ? 'is-invalid' : '')}
              placeholder="Số điện thoại nhận cuộc gọi"
              value={numberToAdd}
              onChange={(e) => changeNumberValue(e)}
              onKeyDown={(e) => {
                if (!disallowToAddNumber() && e.keyCode === 13) {
                  addNumber();
                }
              }}
              disabled={showStartTrial()}
            />
            {(isNumberInvalid && numberToAdd && numberToAdd.length >= 10) && <div className="invalid-feedback">Số điện thoại sai định dạng</div>}
          </div>
          <button className="btn btn-success" onClick={() => addNumber()} disabled={disallowToAddNumber()}>
            <i className="fa fa-plus-circle" /> Thêm
          </button>
        </div>
        <div className="alice-phone-numbers margin-top-10">
          {
            listNumbers.map(number =>
              <div className="margin-right-10" key={number}>
                {number} {!showStartTrial() && <i className="fa fa-close text-danger pointer" onClick={() => removeNumber(number)} />}
              </div>
            )
          }
        </div>
        <div className="alice-buttons">
          <div className="btn-group w-100 d-flex">
            {
              showCreateTrial() &&
                <button
                  className="btn btn-primary btn-lg"
                  onClick={() => createTrialCampaign()}
                  disabled={!listNumbers.length || !speechContent || isContentInvalid || isCreatingTrialCampaign}
                >
                  {isCreatingTrialCampaign && <i className="fa fa-spin fa-spinner" />} {' '}
                  Tạo chiến dịch gọi tự động
                </button>
            }
            {
              showStartTrial() &&
                <button
                  className="btn btn-success btn-lg"
                  onClick={() => startTrialCampaign()}
                  disabled={isStartingTrialCampaign}
                >
                  {isStartingTrialCampaign && <i className="fa fa-spin fa-spinner" />} {' '}
                  Bắt đầu gọi
                </button>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(AliceTrialScreen);
