import HCall from './HCall';

var HOutgoingCall = function(session, config, helper) {
  HCall.apply(this, arguments);
}

// inheritance from HCall
HOutgoingCall.prototype = new HCall();

// function cancel()
// usage: call.cancel()
// -----------------------------------------
// Cancel a call before inprocessing
HOutgoingCall.prototype.cancel = function() {
  this.session.cancel();
}

export default HOutgoingCall;
