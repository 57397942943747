import React from 'react';

var LayoutStateContext = React.createContext();
var LayoutDispatchContext = React.createContext();

function layoutReducer(state, action) {
  const field = action.field;
  const value = action.value;
  switch (action.type) {
  case 'TOGGLE_SIDEBAR':
    return { ...state, isSidebarOpened: !state.isSidebarOpened };
  case 'DISABLE_CALL':
    return { ...state, isDisableCall: true };
  case 'ENABLE_CALL':
    return { ...state, isDisableCall: false };
  case 'SET':
    state[field] = value;
    return { ...state, ...value };
  default:
    throw new Error(`Unhandled action type: ${action.type}`);
  }
}

function LayoutProvider({ children }) {
  const [state, dispatch] = React.useReducer(layoutReducer, {
    isSidebarOpened: false,
    isDisableCall: false,
    needUpdateQuota: true,
    isInitializingAlice: false,
    isShowSwitchCompany: false,
    isShowRightSidebar: true,
    isMobile: navigator.userAgent.search('Mobile') !== -1
  });

  return (
    <LayoutStateContext.Provider value={state}>
      <LayoutDispatchContext.Provider value={dispatch}>
        {children}
      </LayoutDispatchContext.Provider>
    </LayoutStateContext.Provider>
  );
}

function useLayoutState() {
  var context = React.useContext(LayoutStateContext);
  if (context === undefined) {
    throw new Error('useLayoutState must be used within a LayoutProvider');
  }
  return context;
}

function useLayoutDispatch() {
  var context = React.useContext(LayoutDispatchContext);
  if (context === undefined) {
    throw new Error('useLyoutDispatch must be used within a LayoutProvider');
  }
  return context;
}

export { LayoutProvider, useLayoutState, useLayoutDispatch, toggleSidebar };

function toggleSidebar(dispatch) {
  dispatch({
    type: 'TOGGLE_SIDEBAR'
  });
}
