import React from 'react';
import { useTranslation } from 'react-i18next';
import { useVoiceState } from '../../../../../context/VoiceContext';

export default function() {
  const { voiceState } = useVoiceState();
  const { t } = useTranslation();

  return (
    <span className="text-uppercase">
      {(voiceState === 'ready' || !voiceState) &&
        <span>{t('connecting')}</span>}
      {voiceState === 'unready' &&
        <span>{t('disconnect')}</span>}
      {voiceState === 'registered' &&
        <span>{t('connected')}</span>}
      {(voiceState === 'failed') &&
        <span>{t('connectFailed')}</span>}
    </span>
  );
};
