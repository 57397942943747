import React, { useState, useReducer, useEffect, useRef } from 'react';
import _ from 'lodash';
import Joyride, { STATUS } from 'react-joyride';
import { useLayoutDispatch, useLayoutState } from '../../../context/LayoutContext';
import { useUserState, Roles } from '../../../context/UserContext';
import './OnboardingTodo.scss';
import { useTranslation } from 'react-i18next';
const onboardingReducer = (state, action) => {
  const value = action.value;

  switch (action.type) {
  case 'RUN_TODO':
    return {
      ...state,
      ...{
        [value]: {
          ...state[value],
          run: true
        }
      }
    };
  case 'NEXT_TOUR':
    return {
      ...state,
      ...{
        [value]: {
          ...state[value],
          stepIndex: state[value].stepIndex + 1
        }
      }
    };
  case 'PREV_TOUR':
    return {
      ...state,
      ...{
        [value]: {
          ...state[value],
          stepIndex: state[value].stepIndex - 1
        }
      }
    };
  case 'STOP_TOUR':
    return {
      ...state,
      ...{
        [value]: {
          ...state[value],
          run: false
        }
      }
    };
  default:
    break;
  }
};

function useOutsideAlerter(ref, cb) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        cb && cb();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

export default function() {
  const { t } = useTranslation();
  const [isShowChecklist, setIsShowChecklist] = useState(false);
  const [todos, setTodos] = useState([{
    id: 'outbound',
    label: t('callOut'),
    isCompleted: false
  }, {
    id: 'inbound',
    label: t('callIn'),
    isCompleted: false
  }]);
  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef, function() {
    setIsShowChecklist(false);
  });

  const [remainingTodos, setRemainingTodos] = useState(todos.length);
  const { domain, org } = useUserState();
  const { isMobile, isSidebarOpened } = useLayoutState();
  const layoutd = useLayoutDispatch();

  const [state, dispatch] = useReducer(onboardingReducer, {
    getting_started: {
      run: false,
      stepIndex: 0,
      steps: [
        {
          disableBeacon: true,
          content: (
            <div>
              <div className="text-justify">{t('onboardingStep1')}</div>
            </div>
          ),
          placement: 'right',
          target: '.__user-info',
          title: t('accountInfo')
        },
        {
          disableBeacon: false,
          content: (
            <div>
              <div className="text-justify">{t('onboardingStep2')}</div>
            </div>
          ),
          placement: 'right',
          target: '.__sidebar-calling',
          title: t('dialpad')
        },
        {
          disableBeacon: false,
          content: (
            <div>
              <div className="text-justify">{t('onboardingStep3')}</div>
            </div>
          ),
          placement: 'right',
          target: '.__sidebar-recent',
          title: t('callHistory')
        },
        {
          disableBeacon: false,
          content: (
            <div>
              <div className="text-justify">{t('onboardingStep4')}</div>
            </div>
          ),
          placement: 'right',
          target: '.__sidebar-mall',
          title: t('callCenterManagement')
        },
        {
          disableBeacon: false,
          content: (
            <div>
              <div className="text-justify">{t('onboardingStep5')}</div>
            </div>
          ),
          placement: 'left',
          target: '.ab-kite-desk',
          title: t('chatWithCS')
        },
        {
          disableBeacon: false,
          content: (
            <div>
              <div className="text-justify">{t('onboardingStep6')}</div>
            </div>
          ),
          placement: 'right',
          target: '.__checklist',
          title: t('start')
        }
      ],
      locate: {
        back: t('back'),
        close: t('close'),
        last: t('complete'),
        next: t('next'),
        skip: t('skip')
      }
    },
    getting_started_mobile: {
      run: false,
      stepIndex: 0,
      steps: [
        {
          disableBeacon: true,
          spotlightClicks: true,
          hideCloseButton: true,
          hideFooter: true,
          content: (
            <div>
              <div className="text-justify">{t('onboardingMobileStep1')}</div>
            </div>
          ),
          placement: 'right',
          target: '.__menu',
          title: 'Menu'
        },
        {
          disableBeacon: true,
          hideBackButton: true,
          content: (
            <div>
              <div className="text-justify">{t('onboardingMobileStep2')}</div>
            </div>
          ),
          placement: 'right',
          target: '.__user-info',
          title: t('accountInfo')
        },
        {
          disableBeacon: false,
          content: (
            <div>
              <div className="text-justify">{t('onboardingMobileStep3')}</div>
            </div>
          ),
          placement: 'right',
          target: '.__sidebar-calling',
          title: t('dialpad')
        },
        {
          disableBeacon: false,
          content: (
            <div>
              <div className="text-justify">{t('onboardingMobileStep4')}</div>
            </div>
          ),
          placement: 'right',
          target: '.__sidebar-recent',
          title: t('callHistory')
        },
        {
          disableBeacon: false,
          content: (
            <div>
              <div className="text-justify">{t('onboardingMobileStep5')}</div>
            </div>
          ),
          placement: 'right',
          target: '.__sidebar-mall',
          title: t('callCenterManagement')
        },
        {
          disableBeacon: false,
          content: (
            <div>
              <div className="text-justify">{t('onboardingMobileStep6')}</div>
            </div>
          ),
          placement: 'left',
          target: '.ab-kite-desk',
          title: t('chatWithCS')
        },
        {
          disableBeacon: false,
          content: (
            <div>
              <div className="text-justify">{t('onboardingMobileStep7')}</div>
            </div>
          ),
          placement: 'right',
          target: '.__checklist',
          title: t('start')
        }
      ],
      locate: {
        back: t('back'),
        close: t('close'),
        last: t('complete'),
        next: t('next'),
        skip: t('skip')
      }
    },
    outbound: {
      run: false,
      stepIndex: 0,
      steps: [{
        disableBeacon: true,
        spotlightClicks: true,
        content: (
          <div>
            <div className="text-justify">{t('onboardingOutboundStep1')}</div>
          </div>
        ),
        placement: 'bottom',
        target: '.dialpad-wrapper__phone',
        title: t('inputPhoneNumber')
      }, {
        disableBeacon: true,
        content: (
          <div>
            <div className="text-justify">{t('onboardingOutboundStep2')}</div>
          </div>
        ),
        placement: 'bottom',
        target: '.dialpad-wrapper_call-icon'
      }],
      locate: {
        back: t('back'),
        close: t('close'),
        last: t('complete'),
        next: t('next'),
        skip: t('skip')
      }
    },
    inbound: {
      run: false,
      stepIndex: 0,
      steps: [{
        disableBeacon: true,
        content: (
          <div>
            <div className="text-justify">{t('onboardingInboundStep1')}</div>
          </div>
        ),
        placement: 'bottom',
        target: '.__sidebar-mall',
        title: t('callCenterManagement')
      }],
      locate: {
        back: t('back'),
        close: t('close'),
        last: t('complete'),
        next: t('next'),
        skip: t('skip')
      }
    }
  });

  const isTourCompleted = (tourId) => {
    const data = window.localStorage.getItem(`tour_${tourId}`);

    if (data) {
      try {
        const tour = JSON.parse(data);
        if (tour && tour.isCompleted && tour.domain === org.domain) return true;
      } catch (err) {
        console.error(err);
      }
    }

    return false;
  };

  useEffect(() => {
    if (org && Roles.isManager(org.role)) {
      if (isMobile) {
        _.remove(todos, todo => todo.id === 'inbound');
      }

      const newTodos = todos.map(todo => {
        todo.isCompleted = isTourCompleted(todo.id);
        return todo;
      });

      setTodos(newTodos);

      setTimeout(() => {
        if (!isTourCompleted('getting_started')) {
          if (isMobile) {
            dispatch({ type: 'RUN_TODO', value: 'getting_started_mobile' })
          } else {
            dispatch({ type: 'RUN_TODO', value: 'getting_started' });
          }
        }
      }, 2000);
    }
  }, [org, isMobile]);

  useEffect(() => {
    // If user on mobile, this code used to determine whenever user click on menu, product tour will go to next step
    if (isMobile && isSidebarOpened && state.getting_started_mobile.stepIndex === 0) {
      setTimeout(() => {
        dispatch({ type: 'NEXT_TOUR', value: 'getting_started_mobile' });
      }, 500);
    }
  }, [isSidebarOpened, state.getting_started_mobile.stepIndex]);

  useEffect(() => {
    setRemainingTodos(todos.filter(todo => !todo.isCompleted).length);
  }, [todos]);

  const toggleTodos = () => {
    setIsShowChecklist(!isShowChecklist);
  };

  const startTodo = (id) => {
    setIsShowChecklist(false);
    dispatch({ type: 'RUN_TODO', value: id });
  };

  const handleJoyrideCallback = (data, tour) => {
    if (data.action === 'next' && data.lifecycle === 'complete') {
      // This used to prevent call next tour twice
      if (tour.id === 'getting_started_mobile' && data.index === 0) return;

      dispatch({ type: 'NEXT_TOUR', value: tour.id });
    }

    if (data.action === 'prev' && data.lifecycle === 'complete') {
      dispatch({ type: 'PREV_TOUR', value: tour.id });
    }

    if (data.action === 'skip' || data.action === 'close') {
      dispatch({ type: 'STOP_TOUR', value: tour.id });
    }

    if (data.status === STATUS.FINISHED || data.status === STATUS.SKIPPED) {
      // eslint-disable-next-line no-prototype-builtins
      if (tour.hasOwnProperty('isCompleted')) {
        tour.isCompleted = true;
        setRemainingTodos(todos.filter(todo => !todo.isCompleted).length);
      }

      if (tour.id === 'inbound') {
        window.open(`https://${domain}.${process.env.REACT_APP_HOST}/app#!/mall/callcenter/configuration`, 'antbuddy');
        window.localStorage.setItem('tour_inbound', JSON.stringify({ isCompleted: true, domain: org.domain }));
      } else if (tour.id === 'outbound') {
        window.localStorage.setItem('tour_outbound', JSON.stringify({ isCompleted: true, domain: org.domain }));
      } else if (tour.id === 'getting_started_mobile') {
        window.localStorage.setItem('tour_getting_started', JSON.stringify({ isCompleted: true, domain: org.domain }));
        layoutd({ type: 'TOGGLE_SIDEBAR' })
      } else if (tour.id === 'getting_started') {
        window.localStorage.setItem('tour_getting_started', JSON.stringify({ isCompleted: true, domain: org.domain }));
      }
    }
  };

  if (!remainingTodos) {
    return null;
  }

  return (
    <div className="onboarding-todo-wrapper" ref={wrapperRef}>
      {/* Getting started */}
      <Joyride
        continuous={true}
        run={state.getting_started.run}
        steps={state.getting_started.steps}
        stepIndex={state.getting_started.stepIndex}
        scrollToFirstStep={true}
        showProgress={true}
        showSkipButton={true}
        disableOverlayClose={true}
        callback={(data) => handleJoyrideCallback(data, { id: 'getting_started' })}
        locale={state.getting_started.locate}
        styles={{
          options: {
            zIndex: 10000,
            primaryColor: '#35368d'
          }
        }}
      />

      {/* Getting started mobile */}
      <Joyride
        continuous={true}
        run={state.getting_started_mobile.run}
        steps={state.getting_started_mobile.steps}
        stepIndex={state.getting_started_mobile.stepIndex}
        scrollToFirstStep={true}
        showProgress={true}
        showSkipButton={true}
        disableOverlayClose={true}
        callback={(data) => handleJoyrideCallback(data, { id: 'getting_started_mobile' })}
        locale={state.getting_started_mobile.locate}
        styles={{
          options: {
            zIndex: 10000,
            primaryColor: '#35368d'
          }
        }}
      />

      {/* Checklist */}
      {todos.map(todo => (
        <Joyride
          key={todo.id}
          continuous={true}
          run={state[todo.id].run}
          steps={state[todo.id].steps}
          stepIndex={state[todo.id].stepIndex}
          scrollToFirstStep={true}
          showProgress={true}
          showSkipButton={true}
          disableOverlayClose={true}
          callback={(data) => handleJoyrideCallback(data, todo)}
          locale={state[todo.id].locate}
          styles={{
            options: {
              zIndex: 10000,
              primaryColor: '#35368d'
            }
          }}
        />
      ))}
      <button className="btn btn-secondary btn-onboarding __checklist" onClick={() => toggleTodos()}>
        {t('start')}
      </button>
      <span className="todo-badge">{remainingTodos}</span>
      <div className={`onboarding-checklist ${isShowChecklist ? 'expanded' : ''}`}>
        <div className="onboarding-checklist-header">
          <h2>{t('gettingStarted')}</h2>
          <div className="onboarding-desc">{t('completeCheckListToUse')}</div>
        </div>
        <div className="onboarding-checklist-body">
          <ul className="onboarding-items">
            {todos.map((todo) => (
              <li key={todo.id} className={`onboarding-item ${todo.isCompleted ? 'completed' : ''}`}>
                <a onClick={() => startTodo(todo.id)}>
                  <span className="onboarding-checkmark">
                    <svg width="16px" height="13px" viewBox="0 0 16 13"><defs /><g id="Icon-Check-Mark" stroke="none" fill="white"><path d="M5.4375,12.4668236 L0.21875,7.27932363 C0.0729165077,7.13349013 0,6.94599013 0,6.71682363 C0,6.48765712 0.0729165077,6.30015712 0.21875,6.15432363 L1.375,5.02932363 C1.52083349,4.86265712 1.703125,4.77932363 1.921875,4.77932363 C2.140625,4.77932363 2.33333349,4.86265712 2.5,5.02932363 L6,8.52932363 L13.5,1.02932363 C13.6666665,0.86265712 13.859375,0.779323627 14.078125,0.779323627 C14.296875,0.779323627 14.4791665,0.86265712 14.625,1.02932363 L15.78125,2.15432363 C15.9270835,2.30015712 16,2.48765712 16,2.71682363 C16,2.94599013 15.9270835,3.13349013 15.78125,3.27932363 L6.5625,12.4668236 C6.41666651,12.6334901 6.22916651,12.7168236 6,12.7168236 C5.77083349,12.7168236 5.58333349,12.6334901 5.4375,12.4668236 Z" id="" fill="#ffffff" /></g></svg>
                  </span>
                  <p>{todo.label}</p>
                  <svg width="16px" height="16px" viewBox="0 0 16 16"><defs /><g id="Icon-Caret-Right" stroke="none" fill="none"><path d="M6.82142857,1.03571182 L7.60714286,0.249999404 C7.77380971,0.083332953 7.97619029,0 8.21428571,0 C8.45238113,0 8.65476172,0.083332953 8.82142857,0.249999404 L15.75,7.17855431 C15.9166668,7.34522076 16,7.54760087 16,7.78569572 C16,8.02379058 15.9166668,8.22617068 15.75,8.39283713 L8.82142857,15.321392 C8.65476172,15.4880585 8.45238113,15.5713914 8.21428571,15.5713914 C7.97619029,15.5713914 7.77380971,15.4880585 7.60714286,15.321392 L6.82142857,14.5356796 C6.63095256,14.3690132 6.54166685,14.1666331 6.55357143,13.9285382 C6.56547601,13.6904434 6.65476172,13.4880633 6.82142857,13.3213968 L11.1071429,9.21426375 L0.857142857,9.21426375 C0.619047437,9.21426375 0.416666848,9.13093079 0.25,8.96426434 C0.0833331517,8.79759789 0,8.59521778 0,8.35712293 L0,7.21426851 C0,6.97617366 0.0833331517,6.77379356 0.25,6.6071271 C0.416666848,6.44046065 0.619047437,6.3571277 0.857142857,6.3571277 L11.1071429,6.3571277 L6.82142857,2.24999464 C6.65476172,2.08332818 6.56547601,1.88094808 6.55357143,1.64285323 C6.54166685,1.40475837 6.63095256,1.20237827 6.82142857,1.03571182 Z" id="" fill="#13aa52" /></g></svg>
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="onboarding-checklist-footer" />
      </div>
    </div>
  )
}
