const lang = {
  antRingCallCenter: 'Tổng đài AntRing',
  dialpad: 'Gọi điện',
  callHistory: 'Lịch sử cuộc gọi',
  members: 'Thành viên',
  callCenterManagement: 'Cấu hình',
  settings: 'Cấu hình',
  downloadAntBuddyMobile: 'Tải AntBuddy Mobile',
  downloadAntBuddyPC: 'Tải ứng dụng AntBuddy',
  otherOurProducts: 'Sản phẩm khác',
  logout: 'Đăng xuất',
  needAdminToContinue: 'Cần quyền quản trị để tiếp tục sử dụng',
  selectLanguage: 'Chọn ngôn ngữ',
  save: 'Lưu',
  pleaseCheckYourMicro: 'Vui lòng kiểm tra micro và thử lại',
  incomingCall: 'Cuộc gọi đến',
  change: 'Thay đổi',
  selectCompanyAccount: 'Chọn tài khoản công ty',
  use: 'Dùng',
  vn: 'Tiếng Việt',
  us: 'English',
  typeToSearchMember: 'Nhập để tìm thành viên...',
  call: 'Gọi',
  callInternal: 'Gọi nội bộ',
  callMobile: 'Gọi di động',
  connecting: 'Đang kết nối',
  disconnect: 'Không thể kết nối',
  connected: 'Đã kết nối',
  connectFailed: 'Kết nối thất bại',
  callOut: 'Gọi ra',
  callIn: 'Gọi vào',
  onboardingStep1: 'Bạn có thể thay đổi thông tin tài khoản cá nhân bằng cách click vào Tên hoặc thay đổi Công ty trong trường hợp có nhiều hơn 1 tài khoản Công ty bằng cách click vào “Thay đổi”.',
  onboardingStep2: 'Gọi điện cho bất kỳ số điện thoại trên cùng hoặc ngoài vùng lãnh thổ.',
  onboardingStep3: 'Bạn có thể xem lịch sử các cuộc gọi ra và vào tại đây.',
  onboardingStep4: 'Bạn có thể setup đầu số, kịch bản cuộc gọi, xem báo cáo, hoặc thống kê tại đây.',
  onboardingStep5: 'Chúng tôi sẵn sàng hỗ trợ bạn mọi lúc mọi nơi.',
  onboardingStep6: 'Checklist các tính năng chính để làm quen với hệ thống.',
  chatWithCS: 'Chat với CSKH',
  start: 'Bắt đầu',
  back: 'Quay lại',
  close: 'Đóng',
  complete: 'Hoàn thành',
  next: 'Tiếp theo',
  skip: 'Bỏ qua',
  onboardingMobileStep1: 'Nhấn vào đây để mở menu.',
  onboardingMobileStep2: 'Bạn có thể thay đổi thông tin tài khoản cá nhân bằng cách click vào Tên hoặc thay đổi Công ty trong trường hợp có nhiều hơn 1 tài khoản Công ty bằng cách click vào “Thay đổi”.',
  onboardingMobileStep3: 'Gọi điện cho bất kỳ số điện thoại trên cùng hoặc ngoài vùng lãnh thổ.',
  onboardingMobileStep4: 'Bạn có thể xem lịch sử các cuộc gọi ra và vào tại đây.',
  onboardingMobileStep5: 'Bạn có thể setup đầu số, kịch bản cuộc gọi, xem báo cáo, hoặc thống kê tại đây.',
  onboardingMobileStep6: 'Chúng tôi sẵn sàng hỗ trợ bạn mọi lúc mọi nơi.',
  onboardingMobileStep7: 'Checklist các tính năng chính để làm quen với hệ thống. ',
  accountInfo: 'Thông tin tài khoản',
  onboardingOutboundStep1: 'Nhập số điện thoại của bạn hoặc số điện thoại bất kỳ để trải nghiệm tính năng gọi ra.',
  onboardingOutboundStep2: 'Bạn có thể nhấn phím Enter hoặc click vào đây để bắt đầu gọi. ',
  inputPhoneNumber: 'Nhập số điện thoại',
  onboardingInboundStep1: 'Để trải nghiệm tính năng gọi vào, bạn hãy cấu hình tổng đài tại đây. Liên hệ với CSKH để được hỗ trợ nếu gặp khó khăn.',
  gettingStarted: 'Hướng dẫn sử dụng',
  completeCheckListToUse: 'Hoàn thành những bước sau để sử dụng hệ thống',
  inputInternalNumber: 'Nhập số nội bộ nhân viên',
  thisFieldRequired: 'Trường này là bắt buộc',
  transferCall: 'Chuyển cuộc gọi',
  left: 'Còn lại',
  minutesCallOut: 'phút gọi ra',
  exceedQuota: 'Tài khoản của bạn đã hết thời lượng sử dụng',
  voiceNotReady: 'Hệ thống thoại chưa sẵn sàng',
  callDetail: 'Chi tiết cuộc gọi',
  callStatus: 'Trạng thái',
  callDuration: 'Thời gian gọi',
  seconds: 'giây',
  today: 'Hôm nay',
  yesterday: 'Hôm qua',
  thisweek: 'Tuần này',
  lastweek: 'Tuần trước',
  thismonth: 'Tháng này',
  lastmonth: 'Tháng trước',
  noCall: 'Bạn không thực hiện cuộc gọi nào trong thời gian này',
  all: 'Tất cả',
  missed: 'Gọi nhỡ',
  profile: 'Hồ sơ cá nhân',
  switchCompany: 'Thay đổi công ty',
  changeLanguage: 'Thay đổi ngôn ngữ'
};
export default lang;
