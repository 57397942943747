import React, { useRef } from 'react';
import { withRouter } from 'react-router-dom';
import DigitsMenu from './menu/DigitsMenu';
import InputPhoneNumber from './menu/InputPhoneNumber';
import CallButton from './buttons/CallButton';
import MicCheck from './handlecall/components/MicCheck';
import QuotaLeft from './handlecall/components/QuotaLeft';
import sound0 from '../sounds/dtmf-0.mp3';
import sound1 from '../sounds/dtmf-1.mp3';
import sound2 from '../sounds/dtmf-2.mp3';
import sound3 from '../sounds/dtmf-3.mp3';
import sound4 from '../sounds/dtmf-4.mp3';
import sound5 from '../sounds/dtmf-5.mp3';
import sound6 from '../sounds/dtmf-6.mp3';
import sound7 from '../sounds/dtmf-7.mp3';
import sound8 from '../sounds/dtmf-8.mp3';
import sound9 from '../sounds/dtmf-9.mp3';
import soundstar from '../sounds/dtmf-star.mp3';
import soundhash from '../sounds/dtmf-hash.mp3';

const Menu = ({ location }) => {
  const inputRef = useRef(null);
  const callButtonRef = useRef(null);
  const focusToInput = () => {
    inputRef.current.focus();
  }
  const pressAnimate = (keyString) => {
    const target = document.querySelector(`.${keyString}`);
    if (!target) return;
    target.classList.add('clicked');
    setTimeout(() => {
      target.classList.remove('clicked');
    }, 200);
  }

  const pressSound = (keyString) => {
    const audioEl = document.getElementById('dtmf');
    if (audioEl) {
      switch (keyString) {
      case '0':
        audioEl.src = sound0;
        break;
      case '1':
        audioEl.src = sound1;
        break;
      case '2':
        audioEl.src = sound2;
        break;
      case '3':
        audioEl.src = sound3;
        break;
      case '4':
        audioEl.src = sound4;
        break;
      case '5':
        audioEl.src = sound5;
        break;
      case '6':
        audioEl.src = sound6;
        break;
      case '7':
        audioEl.src = sound7;
        break;
      case '8':
        audioEl.src = sound8;
        break;
      case '9':
        audioEl.src = sound9;
        break;
      case 'asterist':
        audioEl.src = soundstar;
        break;
      case 'hash':
        audioEl.src = soundhash;
        break;
      default:
        break;
      }
    }
  }

  const triggerCall = () => {
    callButtonRef.current.click();
  }

  React.useEffect(() => {
    if (location.pathname === '/app/dashboard') {
      focusToInput();
    }
  }, [location]);

  return (
    <>
      <MicCheck />
      <QuotaLeft />
      <InputPhoneNumber
        className="dialpad-wrapper__phone"
        pressAnimate={pressAnimate}
        pressSound={pressSound}
        inputRef={inputRef}
        triggerCall={triggerCall}
      />

      <DigitsMenu
        menuType="primary"
        className="dialpad-wrapper__digits"
        pressAnimate={pressAnimate}
        pressSound={pressSound}
        focusToInput={focusToInput}
      />
      <CallButton callButtonRef={callButtonRef} />
    </>
  )
}

export default withRouter(Menu);
