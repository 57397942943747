import React from 'react';

export default function ActiveCallHeader() {
  return (
    <div className="pulsate active-call">
      <div />
      <div />
      <div />
    </div>
  )
}
