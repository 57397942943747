import React from 'react';
import { useLayoutDispatch } from '../../../../../context/LayoutContext';

export default function () {
  const layoutDispatch = useLayoutDispatch();

  React.useEffect(() => {
    try {
      checkMic();
    } catch (err) {
      console.log(err);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return null;

  function checkMic() {
    navigator.permissions.query({
      name: 'microphone'
    }).then(function (permissionStatus) {
      permissionStatus.onchange = function () {
        setMicState({ permissionStatus });
      }
      setMicState({ permissionStatus });
    })
  }
  function askMicPermission() {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(function (stream) {
        console.log('mic accessed');
        enableCall();
      })
      .catch(function (err) {
        console.error(err, '[mic denied]');
        disableCall();
      });
  }
  function disableCall() {
    layoutDispatch({ type: 'DISABLE_CALL' });
  }
  function enableCall() {
    layoutDispatch({ type: 'ENABLE_CALL' });
  }
  function setMicState({ permissionStatus }) {
    if (permissionStatus.state === 'denied') {
      disableCall();
    } else if (permissionStatus.state === 'prompt') {
      disableCall();
      askMicPermission();
    } else if (permissionStatus.state === 'granted') {
      enableCall();
    }
  }
}
