import { log, getCallNumber, getCallId, getTalkTime } from './utils';
import { resetMediaStream } from '../../services/call';
import API from '../../apis';
import NotificationService from '../../services/notification';
import { maskPhoneNumber } from '../UserContext';

export default function handleDispatchCallSignal(state, action, addition) {
  var message = { id: state.voiceLogs.length };
  const activeCall = window.ABV.activeCall;
  var { incomingCallNotify } = state;
  const closeNotify = (isFocus) => {
    if (isFocus) {
      incomingCallNotify.status = false;
      if (incomingCallNotify.noti) {
        incomingCallNotify.noti.close();
      }
    }
  }

  if (action.value === 'make_call') {
    message.value = log(`Agent make a call to ${action.phone_number}`);
    setPhoneNumber(action.phone_number);
    setState('outgoing');
    window.sessionStorage.setItem('lastCallNumber', action.phone_number);
    window.ABV.state = 'outgoing';
  } else if (action.value === 'accept_call') {
    message.value = log('Agent accept the call');
  } else if (action.value === 'incoming') {
    const number = getCallNumber(activeCall);
    const callId = getCallId(activeCall);
    message.value = log(`Agent receive a call from ${number}. The call_id is ${callId}`);
    setPhoneNumber(number);
    setState('incoming');
    console.log(NotificationService.windowIsFocus(), 'focus');
    if (!NotificationService.windowIsFocus(closeNotify) && !incomingCallNotify.status) {
      incomingCallNotify.noti = NotificationService.createNotification(addition.t('incomingCall'), maskPhoneNumber(addition.org, number), { requireInteraction: true });
      incomingCallNotify.status = true;
      NotificationService.focusAndClose(incomingCallNotify.noti, (click) => {
        if (click) incomingCallNotify.status = false;
      });
    }
    window.ABV.state = 'incoming';
  } else if (action.value === 'end_call') {
    message.value = log('Agent end the call');
    setState('');
  } else if (action.value === 'hangup') {
    message.value = log('The call end');
    setState('');
    if (action.reason === 'bye') {
      resetMediaStream()
    } else if (action.reason === 'terminated') {
      const { domain, layoutd } = addition;
      const call = action.call;
      const talkTime = getTalkTime(call);
      console.log(talkTime, action.reason, state.callState, window.ABV.state);
      if (window.ABV.state === 'outgoing') {
        // TODO: Refactor, this not safe
        setTimeout(() => {
          API.Call.updateQuotaLeft(domain)
            .then(() => {
              layoutd({ type: 'SET', value: { needUpdateQuota: true } });
            })
        }, 2000);
      }
      window.ABV.state = '';
      window.ABV.activeCall = null;
      window.ABV.accept = false;
    }
  } else if (action.value === 'in_progress') {
    message.value = log('The call is answered');
    setState('in_progress');
  } else if (action.value === 'muted') {
    message.value = log('Agent muted the call');
  } else if (action.value === 'unmuted') {
    message.value = log('Agent unmuted the call');
  } else {
    message.value = log(`Unhandled CALL_SIGNAL: ${action.value}`);
  }

  const result = { ...state, voiceLogs: [...state.voiceLogs, message] };

  return result;

  function setState(callState) {
    state.callState = callState;
    closeNotify(true);
  }

  function setPhoneNumber(phoneNumber) {
    state.phoneNumber = phoneNumber;
  }
}
