import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import moment from 'moment';
import API from '../../../apis';
import { useUserState } from '../../../context/UserContext'
import MaterialIcon from '@material/react-material-icon'
import './index.scss'
import OnLoading from '../../OnLoading/OnLoading'
import { useTranslation } from 'react-i18next';

export default function ({ onClose, callDetail }) {
  const { t } = useTranslation();
  const { domain } = useUserState();

  const [recordURL, setRecordURL] = React.useState(null);
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [playIcon, setPlayIcon] = React.useState('play_arrow');
  const [playText, setPlayText] = React.useState('Play');

  React.useEffect(() => {
    audioControl()
  }, [domain, recordURL]);
  const getStartTime = (h) => {
    const unixTimestamp = h && h.inbound ? h.inbound.unix_timestamp : '';
    if (!unixTimestamp) return '';
    return moment.unix(unixTimestamp).format('LTS')
  }
  const getDate = (timestamp) => {
    return moment.unix(timestamp).format('MM/DD/YYYY');
  }
  const playAudio = (bridgeId) => {
    if (!isPlaying) {
      setIsPlaying(true);
      setPlayIcon('pause');
      setPlayText('Pause')
      API.Call.getCallRecord(domain, bridgeId).then((data) => {
        setRecordURL(data.data);
        document.getElementById('audio-play-record').play();
      }, (error) => {
        console.log(error);
      })
    } else {
      document.getElementById('audio-play-record').pause();
      setIsPlaying(false);
      setPlayIcon('play_arrow');
      setPlayText('Play');
    }
  }
  const CallRecord = () => {
    // React.useEffect(() => {
    //   console.log('Mount au');
    //   return () => console.log('Unmount audio');
    // })
    return (
      <audio id='audio-play-record'>
        <source src={recordURL} />
      </audio>
    );
  }

  const audioControl = () => {
    document.getElementById('audio-play-record').addEventListener('ended', function () {
      this.currentTime = 0;
      setIsPlaying(false);
      setPlayIcon('play_arrow');
      setPlayText('Play');
    }, false);
  }

  return (
    <Modal className='text-center' show={true} onHide={() => onClose()}>
      <Modal.Header closeButton>
        <Modal.Title><h5>{t('callDetail')}</h5></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='text-center'>
          <div className='font-weight-bold mt-1'>
            {callDetail.callee.number}
          </div>
          <div className='mt-1 d-flex text-center'>
            <div className='w-50 text-right'>
              {t('callStatus')} :&nbsp;
            </div>
            <div className='w-50 text-left'>
              <div className=''>
                {callDetail.status_detail}
              </div>
            </div>
          </div>
          <div className='mt-1'>
            {getStartTime(callDetail)} &nbsp; {getDate(callDetail.inbound.unix_timestamp)}
          </div>
          <div className='mt-1'>
            {t('callDuration')} : &nbsp;
            {callDetail.inbound.duration_seconds} {t('seconds')}
          </div>
          <div className='d-flex mt-1'>
            <div className='w-50 d-flex pointer-cursor' onClick={() => { if (callDetail.outbound) playAudio(callDetail.outbound.bridge_id) }}>
              <div className='w-75 text-right'>
                <MaterialIcon icon={playIcon} className="text-primary " /> &nbsp;
              </div>
              <div className='w-25 text-left'>
                {playText}
              </div>
            </div>
            <div className='w-50 d-flex pointer-cursor'>
              <div className='w-20 text-left'>
                <MaterialIcon icon="get_app" className="text-primary " /> &nbsp;
              </div>
              <div className='w-80 text-left'>
                Download
              </div>
            </div>
          </div>
        </div>
        <CallRecord />
        <OnLoading onShow={isPlaying} />
      </Modal.Body>
      <Modal.Footer>
        <Button className='bg-success' variant="primary" onClick={() => { onClose() }}>
          {t('close')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
