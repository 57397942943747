/* global _ */
import API from '../../apis';
import { toast } from 'react-toastify';
const initAliceTrial = async (layoutd, userd, history, { org, user }) => {
  if (!isOwner(org, user)) {
    toast.error('Có lỗi xảy ra khi khởi tạo trải nghiệm gọi tự động, vui lòng thử lại sau', {
      autoClose: 3000,
      hideProgressBar: true,
      position: toast.POSITION.TOP_CENTER
    });
    return;
  }
  layoutd({ type: 'SET', value: { isInitializingAlice: true } });
  try {
    if (!alreadyInitAlice(org)) {
      await API.Alice.initAlice(org.domain);
      const newOrg = org;
      _.set(newOrg, 'metadata.initialize.provision_alice', true);
      userd({ type: 'SET', value: { org: newOrg } });
    }
  } catch (e) {
    toast.error('Có lỗi xảy ra khi khởi tạo trải nghiệm gọi tự động, vui lòng thử lại sau', {
      autoClose: 3000,
      hideProgressBar: true,
      position: toast.POSITION.TOP_CENTER
    });
    layoutd({ type: 'SET', value: { isInitializingAlice: false } });
    throw e;
  }
  layoutd({ type: 'SET', value: { isInitializingAlice: false } });
  // redirect
  history.push('/app/alice-trial');
};

function alreadyInitAlice(org) {
  if (org) {
    return _.get(org, 'metadata.initialize.provision_alice');
  }

  return false;
}

const getUserOrg = (org, user) => {
  var _orgs = _.get(user, 'orgs', []);
  var key = _.get(org, 'key', '0');
  var orgResult = _.filter(_orgs, (_org) => {
    var _key = _.get(_org, 'orgKey', '1');
    return _.isEqual(key, _key);
  });
  return _.first(orgResult);
}

const isOwner = (org, user) => {
  var _org = getUserOrg(org, user);
  if (!_org) return false;
  var roles = _.get(_org, 'role', '');
  return roles.indexOf('owner') > -1;
}

export { initAliceTrial, isOwner }
