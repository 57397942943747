import React from 'react';
import ActiveCallHeader from '../../activecall/ActiveCallHeader';
import ActiveCallAvatar from '../../activecall/ActiveCallAvatar';
import ActiveCallPhoneNumber from '../../activecall/ActiveCallPhoneNumber';
import ActiveCallDuration from '../../activecall/ActiveCallDuration';

export default function CallPad({ timer }) {
  return (
    <div className="dialpad-wrapper__call-pad">
      <ActiveCallHeader />
      <ActiveCallAvatar />
      <ActiveCallPhoneNumber />
      <ActiveCallDuration timer={timer} />
    </div>
  )
}
