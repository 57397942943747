import React from 'react';

var CallMenuStateContext = React.createContext();
var CallMenuDispatchContext = React.createContext();

function callMenuReducer(state, action) {
  switch (action.type) {
  case 'SET_PHONE':
    return { ...state, phoneNumber: action.number };
  case 'SET_DTMF':
    return { ...state, dtmfNumber: action.number };
  default:
    throw new Error(`Unhandled action type: ${action.type}`);
  }
}

function CallMenuProvider({ children }) {
  var [state, dispatch] = React.useReducer(callMenuReducer, {
    phoneNumber: '',
    dtmfNumber: ''
  });

  return (
    <CallMenuStateContext.Provider value={state}>
      <CallMenuDispatchContext.Provider value={dispatch}>
        {children}
      </CallMenuDispatchContext.Provider>
    </CallMenuStateContext.Provider>
  );
}

function useCallMenuState() {
  var context = React.useContext(CallMenuStateContext);
  if (context === undefined) {
    throw new Error('useCallMenuState must be used within a CallMenuProvider');
  }
  return context;
}

function useCallMenuDispatch() {
  var context = React.useContext(CallMenuDispatchContext);
  if (context === undefined) {
    throw new Error('useCallMenuDispatch must be used within a CallMenuProvider');
  }
  return context;
}

export { CallMenuProvider, useCallMenuState, useCallMenuDispatch };
