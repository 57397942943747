import React from 'react';
import { accept } from '../../../../services/call';
import { useVoiceDispatch } from '../../../../context/VoiceContext';

export default function AnswerButton({ left, right }) {
  const voiceDispatch = useVoiceDispatch();

  var callStyles = {};
  if (left) callStyles = { ...callStyles, left: left };
  if (right) callStyles = { ...callStyles, right: right };

  const acceptCall = async () => {
    voiceDispatch({ type: 'CALL_SIGNAL', value: 'accept_call' });
    await accept();
  };

  return (
    <div className="call answer-call" onClick={acceptCall} style={callStyles}>
      <span />
    </div>
  )
}
