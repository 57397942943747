import { log } from './utils';

export default function handleDispatchConnection(state, action) {
  var message = {
    id: state.voiceLogs.length
  };
  if (action.value === 'connected') {
    state.voiceState = 'connected';
    message.value = log('Voice is connected');
  } else if (action.value === 'unready') {
    state.voiceState = 'unready';
    message.value = log('Voice is unready');
  } else {
    message.value = log(`Unhandled CONNECTION: ${action.value}`);
  }

  return { ...state, voiceLogs: [...state.voiceLogs, message] };
}
