import React from 'react';
import RecentButton from '../buttons/RecentButton';
import BackscapeButton from '../buttons/BackscapeButton';
import { useCallMenuState, useCallMenuDispatch } from '../../../../context/CallMenuContext';
import { useTranslation } from 'react-i18next';

function Input({ inputRef, pressAnimate, pressSound, triggerCall, ...props }) {
  const { t } = useTranslation();
  const state = useCallMenuState();
  const callMenuDispatch = useCallMenuDispatch();

  const validatePasteInput = (number) => {
    number = number.replace(/[^0-9*#+]/g, '');
    callMenuDispatch({ type: 'SET_PHONE', number: number });
  }

  const isMobile = () => {
    var userAgent = navigator.userAgent;
    if (userAgent.search('Mobile') !== -1) return true;
    return false;
  }

  const validateDigitInput = (key, keyCode) => {
    if (keyCode === 13) {
      if (triggerCall) triggerCall();
    }

    var number;
    switch (key) {
    case '#':
      number = 'hash';
      break;
    case '*':
      number = 'asterist';
      break;
    default:
      number = key;
    }

    pressSound(number);

    if (pressAnimate) pressAnimate('num' + number);
    return 0;
  }

  return (
    <input
      className={state.phoneNumber ? 'dialpad-wrapper__input' : 'dialpad-wrapper__input small-ft'}
      placeholder={t('inputPhoneNumber')}
      readOnly={isMobile()}
      type='text'
      value={state.phoneNumber}
      onKeyDown={(e) => validateDigitInput(e.key, e.keyCode)}
      onChange={(e) => validatePasteInput(e.target.value)}
      ref={inputRef}
      {...props}
    />
  )
}

export default function InputPhoneNumber({ inputRef, className, pressAnimate, pressSound, triggerCall }) {
  return (
    <div className={className}>
      <RecentButton />
      <Input
        inputRef={inputRef}
        pressAnimate={pressAnimate}
        pressSound={pressSound}
        triggerCall={triggerCall}
      />
      <BackscapeButton />
    </div>
  )
}
