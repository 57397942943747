import _ from 'lodash';

const assignSelectedOrg = (user, orgKey) => {
  var anttelOrg = _.find(user.listOrgs, ['key', orgKey]);
  var anttelUser = _.find(user.orgs, ['orgKey', orgKey]);
  return { org: anttelOrg, user: anttelUser };
};

const formatToMinutes = function(duration, _format) {
  if (_.isUndefined(duration)) return '';
  else if (duration === 0 && _format === 'hh:mm:ss') { return '00:00:00'; }

  duration = parseFloat(duration);
  var seconds = duration % 60;
  var minutes = ((duration - seconds) / 60) % 60;
  var hours = Math.floor((duration - seconds) / 3600);
  var type = 'numbers';

  if (!seconds) seconds = 0;
  if (!minutes) minutes = 0;
  if (!hours) hours = 0;

  if (hours < 10 && type === 'numbers') {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }

  if (type === 'verbose') {
    duration =
      hours + ' hours ' + minutes + ' minutes and ' + seconds + ' seconds';
  } else {
    if (hours === '00') {
      if (_format) { duration = '00:' + minutes + ':' + seconds; } else { duration = minutes + ':' + seconds; }
    } else {
      duration = hours + ':' + minutes + ':' + seconds;
    }
  }

  if (duration === '00:00:00' || duration === '00:00') return '';

  return duration;
}

const minutesToSeconds = function(minutes) {
  return _.toInteger(minutes) * 60 + _.toInteger(((minutes % 1) * 60));
}

export default { assignSelectedOrg, formatToMinutes, minutesToSeconds }
