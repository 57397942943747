import React from 'react';
import MaterialIcon from '@material/react-material-icon'
import Button from './Button';

export default function TransferButton({ isShowTransfer, callback }) {
  const activeClassName = () => {
    return isShowTransfer ? ' active' : '';
  };

  return (
    <Button onClick={callback} className={activeClassName()}>
      <MaterialIcon icon="swap_horiz" />
    </Button>
  )
}
