
import React from 'react';
import Dialpad from '../../../components/Dialpad/Dialpad';
import Payment from '../../../components/Payment/Payment';
import Numbers from '../../../components/Numbers/Numbers';
import MemberList from '../../../components/MemberList/MemberList';
import Settings from '../../../components/Settings/Settings';
import CallHistory from '../../../components/CallHistory/CallHistory';
import AliceTrialScreen from '../../../components/AliceTrial/AliceTrialScreen';
import Header from '../../../components/Header/Header';
import Sidebar from '../../../components/Sidebar/Sidebar';
import { useLayoutState } from '../../../context/LayoutContext';
import { Route, Switch } from 'react-router-dom';
import AudioControl from '../../../components/Dialpad/components/AudioControl';
import { useMediaQuery } from 'react-responsive';

export default function DashboardPage() {
  const { isSidebarOpened } = useLayoutState();
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  });

  return (
    <div className={'dashboard-wrapper-mobile ' + (!isTabletOrMobileDevice && isSidebarOpened ? 'sidebar-show-off' : '')}>
      <Header />
      <Sidebar isSidebarOpened={isSidebarOpened} />
      <Route path="/app" component={Dialpad} />
      <Switch>
        <Route path="/app/payment" component={Payment} />
        <Route path="/app/numbers" component={Numbers} />
        <Route path="/app/history" component={CallHistory} />
        <Route path="/app/members" component={MemberList} />
        <Route path="/app/settings" component={Settings} />
        <Route path="/app/alice-trial" component={AliceTrialScreen} />
      </Switch>
      <AudioControl />
    </div>
  );
}
