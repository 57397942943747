import React from 'react';
import { withRouter } from 'react-router-dom';
import MaterialIcon from '@material/react-material-icon';

const MiniButton = ({ history, className }) => {
  return (
    <div className={'mini-call-button ' + className} onClick={() => history.push({ pathname: '/app/dashboard' })}>
      <MaterialIcon
        icon='dialpad'
        style={{
          color: '#35368d',
          marginTop: '20px',
          marginLeft: '20px',
          fontSize: '30px'
        }}
      />
    </div>
  )
}

export default withRouter(MiniButton);
