import * as User from './list/user';
import * as Call from './list/call';
import * as Alice from './list/alice';
import * as Helper from './apiHelper';

const API = {
  User,
  Call,
  Alice,
  Helper
};

export default API;
