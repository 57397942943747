import React from 'react';
import { useUserState, maskPhoneNumber } from '../../../../context/UserContext';
import { useVoiceState } from '../../../../context/VoiceContext';

export default function ActiveCallPhoneNumber() {
  const { phoneNumber } = useVoiceState();
  const { org } = useUserState();

  return (
    <div className="ca-number long-text">
      {maskPhoneNumber(org, phoneNumber)}
    </div>
  )
}
