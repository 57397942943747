import React from 'react';
import { withRouter } from 'react-router-dom';
import API from '../../../../../apis';
import Utils from '../../../../../utils';
import { signOut, useAuthenticateDispatch } from '../../../../../context/AuthenticateContext';
import { useUserState } from '../../../../../context/UserContext';
import { useLayoutState, useLayoutDispatch } from '../../../../../context/LayoutContext';
import MaterialIcon from '@material/react-material-icon'
import { useTranslation } from 'react-i18next';

const QuotaLeft = () => {
  const { t } = useTranslation();
  const { user, domain } = useUserState();
  const { needUpdateQuota } = useLayoutState();
  const layoutd = useLayoutDispatch();
  const [remainBillingDuration, setRemainBillingDuration] = React.useState(0);
  const [isActive, setIsActive] = React.useState(true);
  const [visibility, setVisibility] = React.useState(false);
  const authenticateDispatch = useAuthenticateDispatch();

  React.useEffect(() => {
    if (user && domain && needUpdateQuota) {
      initializeQuota(domain, setRemainBillingDuration, setIsActive, layoutd);
    }
  }, [user, domain, needUpdateQuota]); // eslint-disable-line react-hooks/exhaustive-deps

  return visibility ? (
    <div className="text-center text-uppercase">
      {
        isActive
          ? (<span className="text-muted small">({t('left')} {remainBillingDuration} {t('minutesCallOut')})</span>)
          : (
            <span className="text-danger small">
              <MaterialIcon icon="error_outline" />
              {t('exceedQuota')}
            </span>
          )
      }
    </div>
  ) : null;
  ;

  async function initializeQuota(domain, setRemainBillingDuration, setIsActive, layoutd) {
    try {
      const response = await API.Call.getQuotaLeft(domain);
      var account = response.data.account;

      if (account.billable_account === 'undefined') {
        setVisibility(false);
      } else if (account.billable_account && account.current_minutes > 0) {
        const remainDuration = Utils.formatToMinutes(Utils.minutesToSeconds(account.current_minutes));
        setRemainBillingDuration(remainDuration);
        setVisibility(true);
      } else {
        setIsActive(false);
      }
      layoutd({ type: 'SET', value: { needUpdateQuota: false } });
    } catch (err) {
      signOut(authenticateDispatch);
    }
  }
}

export default withRouter(QuotaLeft);
