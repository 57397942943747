import React from 'react';
import DeleteIcon from '../../images/delete.png';
import { useCallMenuState, useCallMenuDispatch } from '../../../../context/CallMenuContext';

export default function BackscapeButton() {
  const state = useCallMenuState();
  const dispatch = useCallMenuDispatch();

  const clearLast = () => {
    dispatch({ type: 'SET_PHONE', number: state.phoneNumber.substring(0, state.phoneNumber.length - 1) });
  }

  return (
    <div className="dialpad-wrapper__clear" onClick={clearLast}>
      <img alt="delete-icon" src={DeleteIcon} width="24px" />
    </div>
  )
}
