import { axiosWithBearer, tokenDoesNotExist, withSubdomain } from '../apiHelper';

const rootUrl = process.env.REACT_APP_DOMAIN;

function getQuotaLeft(subdomain) {
  if (tokenDoesNotExist()) return Promise.reject(new Error('TOKEN NOT FOUND'));
  return axiosWithBearer().get(withSubdomain(`${rootUrl}/api/organizations/get-quota-left`, subdomain));
}

function updateQuotaLeft(subdomain, data) {
  if (tokenDoesNotExist()) return Promise.reject(new Error('TOKEN NOT FOUND'));
  return axiosWithBearer().post(withSubdomain(`${rootUrl}/api/organizations/update-quota-left`, subdomain), data);
}

function getHistorySummary(subdomain, filter) {
  if (tokenDoesNotExist()) return Promise.reject(new Error('TOKEN NOT FOUND'));
  return axiosWithBearer().post(withSubdomain(`${rootUrl}/api/calls/histories/summary`, subdomain), filter);
}

function getHistory(subdomain, filter) {
  if (tokenDoesNotExist()) return Promise.reject(new Error('TOKEN NOT FOUND'));
  return axiosWithBearer().post(withSubdomain(`${rootUrl}/api/calls/histories`, subdomain), filter);
}

export { getQuotaLeft, updateQuotaLeft, getHistorySummary, getHistory };
