import React from 'react';
import _ from 'lodash';
import Cookies from 'js-cookie';

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
  case 'ASSIGN':
    return handleDispatchAssign(state, action);
  case 'REFRESH_CUR_DOMAIN':
    return handleDispatchRefreshCurDomain(state, action);
  case 'SET':
    return { ...state, ...action.value };
  default:
    throw new Error(`Unhandled action type: ${action.type}`);
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    user: null,
    domain: null,
    org: null
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error('useUserState must be used within a UserProvider');
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserProvider');
  }
  return context;
}

function handleDispatchAssign(state, action) {
  return {
    ...state,
    ...action
  };
}

function handleDispatchRefreshCurDomain(state) {
  var user = state.user;
  if (!user) return state;

  const { org } = getCurrentOrg(user);
  return { ...state, domain: org.domain, org: org };
}

const getCurrentOrg = (user) => {
  const credential = Cookies.get('credential', { domain: Cookies.get('rootDomain') });
  if (credential) {
    const credentialObject = JSON.parse(credential);
    const identity = credentialObject.identity;
    const orgKey = identity.split('_')[1];
    const userOrg = _.find(user.listOrgs, ['key', orgKey]);
    const infoInOrgs = _.find(user.orgs, ['orgKey', orgKey]);

    if (!userOrg) {
      Cookies.remove('credential', { domain: Cookies.get('rootDomain') });
      window.location.reload();
    }

    return {
      domain: userOrg.domain,
      orgKey,
      org: {
        ..._.find(user.listOrgs, ['domain', userOrg.domain]),
        ..._.pick(infoInOrgs, ['beeRole', 'role', 'settings'])
      }
    }
  } else {
    return undefined;
  }
};

const prefixUrl = (url) => {
  if (url && /^img.*/.test(url)) {
    url = `https://${process.env.REACT_APP_HOST}/${url}`;
  }

  return url;
}

const isBeeRoleTelesales = (org) => {
  return org.beeRole && org.beeRole.indexOf('telesales') >= 0;
};

const maskPhoneNumber = (org, number) => {
  if (isBeeRoleTelesales(org)) {
    return number.replace(/\d{3}(\d{4})$/, '***$1');
  }

  return number;
};

const Roles = {
  isAdmin: (role) => {
    return role && role.indexOf('manager') >= 0;
  },
  isOwner: (role) => {
    console.log(role);
    return role && role.indexOf('owner') >= 0;
  },
  isManager: (role) => {
    return (role && role.indexOf('owner') >= 0) || (role && role.indexOf('manager') >= 0);
  },
  isSupervisor: (role) => {
    return role && role.indexOf('supervisor') >= 0;
  }
};

export { UserProvider, useUserState, useUserDispatch, prefixUrl, maskPhoneNumber, Roles, getCurrentOrg };
