import React from 'react';
import handleDispatchInit from './Voice/init';
import handleDispatchConnection from './Voice/connection';
import handleDispatchRegistration from './Voice/registration';
import handleDispatchCallSignal from './Voice/callSignal';
import handleDispatchInform from './Voice/inform';
import handleDispatchAssign from './Voice/assign';

import { useUserState } from './UserContext'
import { useLayoutDispatch } from './LayoutContext';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

var VoiceStateContext = React.createContext();
var VoiceDispatchContext = React.createContext();

function VoiceProvider({ children }) {
  const { t } = useTranslation();
  const { user, org, domain } = useUserState();
  const layoutd = useLayoutDispatch();

  function voiceReducer(state, action) {
    switch (action.type) {
    case 'INIT':
      return handleDispatchInit(state, action);
    case 'CONNECTION':
      return handleDispatchConnection(state, action);
    case 'REGISTRATION':
      return handleDispatchRegistration(state, action);
    case 'CALL_SIGNAL':
      return handleDispatchCallSignal(state, action, { user, org, domain, layoutd, t });
    case 'INFORM':
      return handleDispatchInform(state, action);
    case 'ASSIGN':
      return handleDispatchAssign(state, action);
    default:
      throw new Error(`Unhandled voice action type: ${action.type}`);
    }
  }

  var [state, dispatch] = React.useReducer(voiceReducer, {
    voiceRegistered: false,
    voiceLogs: [],
    voiceState: null,
    callState: null,
    phoneNumber: null,
    firstRegister: false,
    incomingCallNotify: { status: false, noti: null }
  })

  return (
    <VoiceStateContext.Provider value={state}>
      <VoiceDispatchContext.Provider value={dispatch}>
        {children}
      </VoiceDispatchContext.Provider>
    </VoiceStateContext.Provider>
  );
}

function useVoiceState() {
  var context = React.useContext(VoiceStateContext);
  if (context === undefined) {
    throw new Error('useVoiceState must be used within a VoiceProvider');
  }
  return context;
}

function useVoiceDispatch() {
  var context = React.useContext(VoiceDispatchContext);
  if (context === undefined) {
    throw new Error('useVoiceDispatch must be used within a VoiceProvider');
  }
  return context;
}

export { VoiceProvider, useVoiceState, useVoiceDispatch, registerVoice };

function registerVoice(user, selected, dispatch) {
  // TODO: does not use default device
  const config = {
    displayName: user.username,
    uri: `${selected.user.anttel.devices[0].username}@${selected.org.anttel.realm}`,
    authorizationUser: `${selected.user.anttel.devices[0].username}`,
    password: `${selected.user.anttel.devices[0].password}`,
    stunServers: []
  };

  const setCookies = (selected, config) => {
    Cookies.set('credential', JSON.stringify({
      identity: `${selected.user._id}_${selected.org.key}`,
      config: config
    }), { domain: Cookies.get('rootDomain') });
    Cookies.set('domain', selected.org.domain, { domain: Cookies.get('rootDomain') });
  };

  setCookies(selected, config);
  dispatch({ type: 'INIT', config: config, dispatch: dispatch });
}
