import React from 'react';
import AuthHandler from '../components/AuthHandler';
import Layout from './Layout';

const App = () => {
  return (
    <AuthHandler
      defaultPage="/app/dashboard"
      layout={Layout}
    />
  );
}

export default App;
