import React from 'react';
import MaterialIcon from '@material/react-material-icon';
import { useMediaQuery } from 'react-responsive';
import { useLayoutState, useLayoutDispatch } from '../../context/LayoutContext';
import OnboardingTodo from './components/OnboardingTodo';
import './index.scss';
import Logo from './images/logo.png';

const Branch = ({ ...props }) => {
  return (
    <div {...props}>
      <div className="logo-wrapper">
        <img alt="logo" src={Logo} />
      </div>
    </div>
  )
}
export default function MobileHeader() {
  const layoutDispatch = useLayoutDispatch();
  const { isSidebarOpened } = useLayoutState();
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  });

  const openSidebar = () => {
    layoutDispatch({ type: 'TOGGLE_SIDEBAR' });
  }

  return (
    <>
      <div className="header-wrapper-mobile">
        <MaterialIcon icon="menu" className="__menu" onClick={() => openSidebar()} />
        <Branch className={'__branch ' + (!isTabletOrMobileDevice && isSidebarOpened ? 'sidebar-show-off' : '')} />
        <OnboardingTodo />
      </div>
      <div className="header-padding" />
    </>
  );
}
