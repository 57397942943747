import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import API from '../../apis';
import { useUserState, maskPhoneNumber } from '../../context/UserContext'
import OnLoading from '../OnLoading';
import './index.scss';
import MaterialIcon from '@material/react-material-icon'
import { useVoiceDispatch } from '../../context/VoiceContext';
import CallDetailModal from './subcomponents/CallDetailModal'
import { useLayoutState } from '../../context/LayoutContext';
import { withRestrictCall } from '../../services/withs';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

export default function () {
  const { t } = useTranslation();
  const { isDisableCall } = useLayoutState();
  const { isSidebarOpened } = useLayoutState();
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)'
  });
  const { user, org, domain } = useUserState();
  const [callHistories, setCallHistories] = React.useState({
    initialized: false,
    data: []
  });
  const [viewType, setViewType] = React.useState('all');
  const [isLoading, setIsLoading] = React.useState(false);
  const [dataDetail] = React.useState([]);
  const [showDetailModal, setShowDetailModal] = React.useState(false);
  const filterOptions = [
    {
      text: t('today'),
      id: 'today',
      filter: {
        created_from: moment().startOf('day').toISOString(),
        created_to: moment().endOf('day').toISOString()
      }
    },
    {
      text: t('yesterday'),
      id: 'yesterday',
      filter: {
        created_from: moment().subtract(1, 'day').startOf('day').toISOString(),
        created_to: moment().subtract(1, 'day').endOf('day').toISOString()
      }
    },
    {
      text: t('thisweek'),
      id: 'thisweek',
      filter: {
        created_from: moment().startOf('week').startOf('day').toISOString(),
        created_to: moment().endOf('week').endOf('day').toISOString()
      }
    },
    {
      text: t('lastweek'),
      id: 'lastweek',
      filter: {
        created_from: moment().subtract(1, 'week').startOf('week').startOf('day').toISOString(),
        created_to: moment().subtract(1, 'week').endOf('week').endOf('day').toISOString()
      }
    },
    {
      text: t('thismonth'),
      id: 'thismonth',
      filter: {
        created_from: moment().startOf('month').startOf('day').toISOString(),
        created_to: moment().endOf('day').toISOString()
      }
    },
    {
      text: t('lastmonth'),
      id: 'lastmonth',
      filter: {
        created_from: moment().subtract(1, 'month').startOf('month').startOf('day').toISOString(),
        created_to: moment().subtract(1, 'month').startOf('month').endOf('day').toISOString()
      }
    }
  ];
  const voiced = useVoiceDispatch()

  const [selectedRange, setSelectedRange] = React.useState(filterOptions[0].id);

  React.useEffect(() => {
    if (user && domain && !callHistories.initialized) {
      initializeCallHistory();
    }
  }, [domain, callHistories]); // eslint-disable-line react-hooks/exhaustive-deps

  const History = {
    getCallTarget: function (h) {
      if (h.status === 'outgoing') {
        return h && h.callee ? maskPhoneNumber(org, h.callee.number) : ''
      } else if (h.status === 'incoming' || h.status === 'missed') {
        return h && h.caller ? maskPhoneNumber(org, h.caller.number) : ''
      } else if (h.status === 'internalgoing') {
        if (h.caller && h.caller.username === user.username) {
          return h.callee ? maskPhoneNumber(org, h.callee.number) : ''
        } else {
          return h.caller ? maskPhoneNumber(org, h.caller.number) : ''
        }
      }
    },
    getStartTime: function (h) {
      const unixTimestamp = h && h.inbound ? h.inbound.unix_timestamp : '';
      if (!unixTimestamp) return '';
      return moment.unix(unixTimestamp).format('HH:mm')
    }
  }

  const IndicatorCallDirection = ({ call }) => {
    if (!call) return null;
    let colorText;
    if (call.status_detail === 'SUCCESS') { colorText = 'text-success'; } else { colorText = 'text-danger'; }
    if (call.status === 'incoming') return <MaterialIcon icon="call_received" className="text-success" />
    else if (call.status === 'outgoing') return <MaterialIcon icon="call_made" className="text-success" />
    else if (call.status === 'missed') return <MaterialIcon icon="call_missed" className="text-danger" />
    else if (call.status === 'internalgoing') return <i className={'fa fa fa-exchange ' + colorText} />
    return null;
  }

  const onSelectedRangeChange = (e) => {
    setSelectedRange(e.target.value);
    initializeCallHistory(e.target.value);
  };

  const handleMakeCall = async (number) => {
    const call = withRestrictCall({ isDisableCall, t });
    if (call) {
      await call(number, voiced);
    }
  };

  const TableCallHistories = ({ callHistories }) => {
    if (Object.keys(callHistories.data).length) {
      return (
        _.map(callHistories.data, (histories, dateGroup) => {
          return [
            <h5 key='date-head' className="text-center font-weight-bold">{dateGroup}</h5>,
            <table key='table' className="table">
              <tbody>
                {
                  histories.map((history) => (viewType === 'all' || (viewType === 'missed' && history.status === 'missed')) ? (
                    <tr key={history.key} className='align-middle'>
                      <td>
                        <div className='call-direction'>
                          <IndicatorCallDirection call={history} />
                        </div>
                        {/* <div className='call-status'> {history.status_detail} </div> */}
                      </td>
                      <td className="w-30 pointer align-middle">
                        {History.getCallTarget(history)}
                      </td>
                      <td className='w-30 align-middle text-muted'>
                        <MaterialIcon
                          icon="timer" style={{
                            fontSize: 14,
                            paddingRight: 5
                          }}
                        />
                        {formatDuration(history.inbound.duration_seconds)}
                      </td>
                      <td className="text-right w-10 align-middle text-muted">{History.getStartTime(history)}</td>
                      <td>
                        <div className="mini-call-btn-wrapper">
                          <MaterialIcon icon="call" className="text-success pointer mini-call-btn" onClick={() => handleMakeCall(History.getCallTarget(history))} />
                        </div>
                      </td>
                      {/* <td>
                        <MaterialIcon icon="info_outline" className="text-success pointer" onClick={(e) => showDetail(history)} />
                      </td> */}
                    </tr>
                  ) : null)
                }
              </tbody>
            </table>
          ]
        })
      )
    } else if (!Object.keys(callHistories.data).length && callHistories.initialized) {
      return (
        <table className="table">
          <tbody>
            <tr>
              <td>
                <span>{t('noCall')}</span>
              </td>
            </tr>
          </tbody>
        </table>
      )
    }
  }

  return (
    <>
      <div className={'call-history-fixed-header ' + (isDesktopOrLaptop && isSidebarOpened ? 'sidebar-show-off' : '')}>
        <div className="call-history-filter">
          <select
            className="form-control"
            value={selectedRange && selectedRange.id}
            onChange={onSelectedRangeChange}
          >
            {
              filterOptions.map(
                filter =>
                  <option key={filter.text} value={filter.id}>{filter.text}</option>
              )
            }
          </select>
        </div>
        <div className="btn-group">
          <button type="button" className={'btn btn-outline-success ' + (viewType === 'all' ? 'active' : '')} onClick={() => setViewType('all')}>{t('all')}</button>
          <button type="button" className={'btn btn-outline-success ' + (viewType === 'missed' ? 'active' : '')} onClick={() => setViewType('missed')}>{t('missed')}</button>
        </div>
      </div>
      {
        showDetailModal &&
          <CallDetailModal
            onClose={() => setShowDetailModal(false)}
            callDetail={dataDetail}
          />
      }
      <div className="call-history-wrapper">
        {
          !isLoading && callHistories.initialized
            ? <TableCallHistories callHistories={callHistories} />
            : <OnLoading onShow={isLoading} />
        }
      </div>
    </>
  );

  async function initializeCallHistory(optionId) {
    optionId = optionId || selectedRange;
    const filter = filterOptions.find(option => option.id === optionId).filter;
    filter.users = user.username;

    // const data = await API.Call.getHistorySummary(domain, filter);
    setIsLoading(true);
    const data = await API.Call.getHistory(domain, filter);
    setIsLoading(false);
    const histories = data.data.reverse();
    const grpHistories = _.groupBy(histories, (history) => {
      return moment.unix(history.inbound.unix_timestamp).format('DD-MM-YYYY');
    });
    setCallHistories({ initialized: true, data: grpHistories });
  }

  // OMG: please don't duplicate me
  function formatDuration(duration) {
    if (_.isUndefined(duration)) return '';

    duration = parseFloat(duration);
    var seconds = duration % 60;
    var minutes = ((duration - seconds) / 60) % 60;
    var hours = Math.floor((duration - seconds) / 3600);
    var type = 'numbers';

    if (!seconds) seconds = 0;
    if (!minutes) minutes = 0;
    if (!hours) hours = 0;

    if (hours < 10 && type === 'numbers') {
      hours = '0' + hours;
    }
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    if (seconds < 10) {
      seconds = '0' + seconds;
    }

    if (hours === '00') {
      duration = minutes + ':' + seconds;
    } else {
      duration = hours + ':' + minutes + ':' + seconds;
    }

    return duration
  }
}
