/* global _ */
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import Utils from '../../utils';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';

export default function ({ user, onClose, onSelectOrg }) {
  const { t } = useTranslation();
  const [selectedOrg, setSelectedOrg] = React.useState({});

  React.useEffect(() => {
    if (user) {
      const credential = Cookies.get('credential', { domain: Cookies.get('rootDomain') });
      if (credential) {
        const credentialObject = JSON.parse(credential);
        // eslint-disable-next-line no-unused-vars
        const [userId, orgKey] = credentialObject.identity.split('_');
        const selected = Utils.assignSelectedOrg(user, orgKey);
        setSelectedOrg(selected);
      } else {
        if (user.listOrgs.length) {
          const defaultSelectedOrgKey = _.first(user.listOrgs).key;
          const selected = Utils.assignSelectedOrg(user, defaultSelectedOrgKey);
          setSelectedOrg(selected);
          if (user.listOrgs.length === 1) {
            onSelectOrg(selected);
            onClose();
          }
        }
      }
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnSelectedChange = (e) => {
    const selected = Utils.assignSelectedOrg(user, e.target.value);
    setSelectedOrg(selected);
  };

  return (
    <Modal show={true} onHide={() => null}>
      <Modal.Header>
        <Modal.Title>{t('selectCompanyAccount')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <select
          className="form-control"
          onChange={handleOnSelectedChange}
          value={selectedOrg && selectedOrg.org ? selectedOrg.org.key : ''}
        >
          {
            user && user.listOrgs.map(
              org =>
                <option key={org.domain} value={org.key}>{`${org.name} (${org.domain})`}</option>
            )
          }
        </select>

      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => { onSelectOrg(selectedOrg); onClose(); }}>
          {t('use')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
