import { axiosWithBearer, withSubdomain } from '../apiHelper';

const rootUrl = process.env.REACT_APP_DOMAIN;

export const initAlice = (domain, payload) => {
  var url = `${rootUrl}/api/alice/init`;
  return axiosWithBearer().post(withSubdomain(url, domain), payload);
};

export const createTrialCampaign = (domain, payload) => {
  var url = `${rootUrl}/api/alice/trial-campaign`;
  return axiosWithBearer().post(withSubdomain(url, domain), payload);
};

export const getTrialCampaign = (domain, campaignId) => {
  var url = `${rootUrl}/api/alice/campaign/:campaignId`;
  return axiosWithBearer().post(withSubdomain(url, domain).replace(':campaignId', campaignId));
};

export const startTrialCampaign = (domain, campaignId) => {
  var url = `${rootUrl}/api/alice/campaign/:campaignId/start`;
  return axiosWithBearer().post(withSubdomain(url, domain).replace(':campaignId', campaignId));
};
