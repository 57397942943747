import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FlagIconFactory from 'react-flag-icon-css'
import { useUserDispatch, useUserState } from '../../context/UserContext';
import { updateUser } from '../../apis/list/user';

const FlagIcon = FlagIconFactory(React, { useCssModules: false })

const Settings = function() {
  const { user, org } = useUserState();
  const { i18n, t } = useTranslation();
  const userDispatch = useUserDispatch();

  const languages = [{
    id: 'vi',
    code: 'vn',
    label: t('vn')
  }, {
    id: 'en',
    code: 'us',
    label: t('us')
  }];

  const [selectedLanguage, setSelectedLanguage] = useState({});

  const changeLanguage = async () => {
    const settings = {
      ...org.settings,
      language: selectedLanguage.id
    };

    await updateUser({
      key: user.key,
      settings
    });

    const newOrg = {
      ...org,
      settings: {
        ...org.settings,
        language: selectedLanguage.id
      }
    };

    i18n.changeLanguage(selectedLanguage.code, async (err, t) => {
      if (err) return console.log('something went wrong loading', err);
      userDispatch({ type: 'ASSIGN', org: newOrg });
    });
  };

  useEffect(() => {
    if (org && selectedLanguage && selectedLanguage.id !== org.settings.language) {
      setSelectedLanguage(languages.find(l => l.id === org.settings.language));
    }
  }, [org]);

  return (
    <div className='p-5'>
      <label htmlFor="" className='font-weight-bold'>{t('selectLanguage')}: </label>
      {languages.map((lang, i) => {
        return (
          <div className="custom-control custom-radio mb-2" key={lang.code}>
            <input type="radio" name="languages" className="custom-control-input" id={`language-radio-${i}`} checked={selectedLanguage && selectedLanguage.id === lang.id} value={lang.id} onChange={(event) => setSelectedLanguage(languages.find(l => l.id === event.target.value))} />
            <label className="custom-control-label" htmlFor={`language-radio-${i}`}>
              <FlagIcon code={lang.code} size="2x" />
              <span className="ml-2">{lang.label}</span>
            </label>
          </div>
        )
      })}
      <div className="mt-4">
        <button className="btn btn-primary btn-sm" onClick={() => changeLanguage()}>{t('save')}</button>
      </div>
    </div>
  )
};

export default Settings;
