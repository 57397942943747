import axios from 'axios';
import Cookies from 'js-cookie';

function axiosWithCredentials() {
  return axios.create({
    withCredentials: true
  });
}

function axiosWithBearer() {
  return axios.create({
    headers: { Authorization: `Bearer ${Cookies.get('token')}` }
  })
}

function tokenDoesNotExist() {
  const token = Cookies.get('token');
  return !token;
}

function refreshTokenDoesNotExist() {
  const token = Cookies.get('refreshToken');
  return !token;
}

function withSubdomain(url, subdomain) {
  var parts = new URL(url);
  return `${parts.protocol}//${subdomain}.${parts.hostname}${parts.pathname}`;
}

function withStaging(url) {
  return withSubdomain(url, 'staging');
}

function withFolder(url, folder, bridgeId) {
  var parts = new URL(url);
  var pathArray = `${parts.pathname}`.split('/');
  var newPathname = '';
  pathArray[3] = folder;
  pathArray[4] = pathArray[4].replace('bridge-id', bridgeId);
  for (let i = 1; i < pathArray.length; i++) {
    newPathname += '/';
    newPathname += pathArray[i];
  }
  return `${parts.protocol}//${parts.hostname}` + newPathname + `${parts.search}`;
}

export {
  axiosWithCredentials,
  axiosWithBearer,
  tokenDoesNotExist,
  refreshTokenDoesNotExist,
  withSubdomain,
  withStaging,
  withFolder
};
