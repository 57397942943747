import React, { useState } from 'react';
import MaterialIcon from '@material/react-material-icon';
import Button from './Button';
import { mute, unmute } from '../../../../services/call';

export default function MuteButton() {
  const [selected, setSelected] = useState(false);
  const handleMuteCall = () => {
    if (selected) {
      unmute();
      setSelected(false);
    } else {
      mute();
      setSelected(true);
    }
  };

  const activeClassName = () => {
    return selected ? ' active' : '';
  };

  return (
    <Button onClick={handleMuteCall} className={activeClassName()}>
      <MaterialIcon icon="mic_off" />
    </Button>
  )
}
