import React from 'react';
import CallPad from './components/CallPad';
import CallFunctions from './components/CallFunctions';
import CallButtons from './components/CallButtons';
import RejectButton from '../buttons/RejectButton';

export default function Outgoing() {
  return (
    <>
      <div className="dialpad-wrapper__handle-screen">
        <CallPad />
        <CallFunctions />
        <CallButtons>
          <RejectButton left="calc(50% - 30px)" />
        </CallButtons>
      </div>
    </>
  );
}
