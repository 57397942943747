const KEYPADS = {
  num1: ['1', ''],
  num2: ['2', 'ABC'],
  num3: ['3', 'DEF'],
  num4: ['4', 'GHI'],
  num5: ['5', 'JKL'],
  num6: ['6', 'MNO'],
  num7: ['7', 'PQRS'],
  num8: ['8', 'TUV'],
  num9: ['9', 'WXYZ'],
  numasterist: ['*', ''],
  num0: ['0', ''],
  numhash: ['#', '']
}

export default KEYPADS;
