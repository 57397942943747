/* global _: false */
import React, { useState } from 'react';
import './index.scss';
import { useUserState } from '../../context/UserContext';
import { Link } from 'react-router-dom';

export default function () {
  const { user, domain } = useUserState();

  const [minLeft, setMinLeft] = useState(0);

  React.useEffect(() => {
    if (user && domain) {
      const listOrgs = user.listOrgs;
      const usrOrg = _.find(listOrgs, ['domain', domain]);
      if (usrOrg && usrOrg.quotas) {
        setMinLeft(usrOrg.quotas.minLeft);
      }
    }
  }, [user, domain])

  const payDomain = process.env.REACT_APP_PAY_DOMAIN;

  return (
    <div className="number-container">
      <div className="row">
        <div className="col-12">
          <div className="font-weight-bold">Lưu lượng sử dụng: </div>
          <div>
            Công ty còn lại {minLeft} phút gọi ra
          </div>
          <div>
            <a href={payDomain} target="_blank" rel="noopener noreferrer">Nâng cấp</a>
          </div>
          <div>
            <Link to="/app/report">Xem báo cáo chi tiết</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
