const NotificationService = {
  windowFocus: true,
  Notification: null,
  requestPermission: function() {
    var self = this;
    if (self.Notification && self.Notification.permission !== 'denied') {
      self.Notification.requestPermission(function(status) {
        if (self.Notification.permission !== status) {
          self.Notification.permission = status;
        }
      });
    }
  },
  createNotification: function(title, text, { image, tag, requireInteraction }) {
    var data = { body: text, requireInteraction: requireInteraction };
    if (tag) data.tag = tag;
    else data.tag = Math.floor(Math.random() * 1000);

    if (image) {
      data.icon = image;
    } else {
      data.icon = './favicon.png';
    }

    return new this.Notification(title, data);
  },
  focusAndClose: function(noti, cb) {
    noti.onclick = function() {
      window.focus();
      noti.close();
      // eslint-disable-next-line standard/no-callback-literal
      if (cb) cb(true);
    }
  },
  windowIsFocus: function(cb) {
    var self = this;
    if (cb) cb(self.windowFocus);
    return self.windowFocus;
  }
};

if (window.Notification) {
  NotificationService.Notification = window.Notification;
} else if (window.webkitNotifications) {
  NotificationService.Notification = window.webkitNotifications;
}

window.addEventListener('focus', function() {
  NotificationService.windowFocus = true;
});

window.onblur = function() {
  NotificationService.windowFocus = false;
};

window.addEventListener('keydown', function(e) {
  if (e.keyCode === 13) {
    e.preventDefault();
  }
});

export default NotificationService;
