import { log, getCallNumber, getCallId } from './utils';

export default function handleDispatchInform(state, action) {
  var message = {
    id: state.voiceLogs.length
  };

  const activeCall = window.ABV.activeCall;
  const number = getCallNumber(activeCall);
  const callId = getCallId(activeCall);

  if (action.value === 'inform_call_id') { message.value = log(`The call_id is ${callId} - number: ${number}`); }

  return { ...state, voiceLogs: [...state.voiceLogs, message] };
}
