import React from 'react';
import './index.scss';
import { useVoiceState } from '../../context/VoiceContext';
import Menu from './components/Menu';
import Outgoing from './components/handlecall/Outgoing';
import Incoming from './components/handlecall/Incoming';
import InProgress from './components/handlecall/InProgress';
import StatusBar from './components/handlecall/StatusBar';
import MiniButton from './components/handlecall/MiniButton';
import { CallMenuProvider } from '../../context/CallMenuContext';
import { withRouter } from 'react-router-dom';
import { useLayoutState, useLayoutDispatch } from '../../context/LayoutContext';
import { useMediaQuery } from 'react-responsive';
import MemberList from '../MemberList/MemberList';
import ShowRightSide from './images/show-right-side.png';

const Dialpad = function (props) {
  const { callState } = useVoiceState();
  const { location } = props;
  const layoutd = useLayoutDispatch();
  const { isSidebarOpened, isShowRightSidebar } = useLayoutState();
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  });
  const isDisplayDialpad = (location && location.pathname === '/app/dashboard') || callState;

  const onClickHide = () => {
    layoutd({ type: 'SET', value: { isShowRightSidebar: false } })
  };

  React.useEffect(() => {
    if (!isTabletOrMobileDevice) {
      if (callState) {
        layoutd({ type: 'SET', value: { isSidebarOpened: false } });
      } else {
        layoutd({ type: 'SET', value: { isSidebarOpened: true } });
      }
    }
  }, [callState, isTabletOrMobileDevice]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (isTabletOrMobileDevice) {
      layoutd({ type: 'SET', value: { isShowRightSidebar: false } })
    }
  }, []);

  return (
    <CallMenuProvider>
      <div className={'dialpad-wrapper ' + (isDisplayDialpad ? '' : 'd-none') + (!isTabletOrMobileDevice && isSidebarOpened && location.pathname === '/app/dashboard' ? 'sidebar-show-off' : '') + ' ' + (!callState ? 'none' : callState)}>
        {
          (callState === 'in_progress') && <InProgress />
        }
        {
          (callState === 'incoming') && <Incoming />
        }
        {
          (callState === 'outgoing') && <Outgoing />
        }
        <div className="flex flex-1 flex-row relative">
          <div className="basis-auto grow">
            <Menu />
          </div>

          {!isShowRightSidebar && (
            <div className="absolute top-0 right-[15px]">
              <button className="btn btn-default border bg-white" onClick={() => layoutd({ type: 'SET', value: { isShowRightSidebar: true } })}>
                <img src={ShowRightSide} width="26" />
              </button>
            </div>
          )}
          {!isTabletOrMobileDevice && isShowRightSidebar && (
            <div className="basis-96 h-screen relative mt-[-15px] mr-[-15px] border-l-2 border-[#dfdfdf]">
              <MemberList onClickHide={() => onClickHide()} />
            </div>
          )}
        </div>
        {isTabletOrMobileDevice && isShowRightSidebar && (
          <div className="absolute top-[55px] left-0 right-0 bottom-0 bg-white">
            <MemberList onClickHide={() => onClickHide()} />
          </div>
        )}
      </div>
      <MiniButton className={(location && location.pathname !== '/app/dashboard' ? '' : ' d-none')} />
      <StatusBar />
    </CallMenuProvider>
  )
}

export default withRouter(Dialpad)
