const lang = {
  antRingCallCenter: 'AntRing call center',
  dialpad: 'Dialpad',
  callHistory: 'Call history',
  members: 'Members',
  callCenterManagement: 'Settings',
  settings: 'Settings',
  downloadAntBuddyMobile: 'Download AntBuddy Mobile',
  downloadAntBuddyPC: 'Download AntBuddy application',
  otherOurProducts: 'Other our products',
  logout: 'Logout',
  needAdminToContinue: 'Require Manager permission to continue',
  selectLanguage: 'Select language',
  save: 'Save',
  pleaseCheckYourMicro: 'Please check your micro and try again',
  incomingCall: 'Incoming call',
  change: 'Change',
  selectCompanyAccount: 'Select company account',
  use: 'Select',
  vn: 'Vietnamese',
  us: 'English',
  typeToSearchMember: 'Search member',
  call: 'Call',
  callInternal: 'Call internal',
  callMobile: 'Call phone',
  connecting: 'Connecting',
  disconnect: 'Disconnect',
  connected: 'Connected',
  connectFailed: 'Disconnect',
  callOut: 'Call out',
  callIn: 'Call internal',
  onboardingStep1: 'Change personal account by click the button "Change" in case there is more than one Company account',
  onboardingStep2: 'Call any phone number in the same regions',
  onboardingStep3: 'You can view your recent calls here',
  onboardingStep4: 'You can setup your number, call flow, view report & statistic here',
  onboardingStep5: 'We are available to support you 24/7',
  onboardingStep6: 'Here is the checklist to get you familiar with our system',
  chatWithCS: 'Chat with Customer Support',
  start: 'Start',
  back: 'Back',
  close: 'Close',
  complete: 'Complete',
  next: 'Next',
  skip: 'Skip',
  onboardingMobileStep1: 'Click here to open menu',
  onboardingMobileStep2: 'Change personal account by click the button "Change" in case there is more than one Company account',
  onboardingMobileStep3: 'Call any phone number in the same regions',
  onboardingMobileStep4: 'You can view your recent calls here',
  onboardingMobileStep5: 'You can setup your number, call flow, view report & statistic here',
  onboardingMobileStep6: 'We are available to support you 24/7',
  onboardingMobileStep7: 'Here is the checklist to get you familiar with our system',
  accountInfo: 'Account Infomation',
  onboardingOutboundStep1: 'Input phone number here to experience making call',
  onboardingOutboundStep2: 'You can press Enter or click here to start calling',
  inputPhoneNumber: 'Input phone number',
  onboardingInboundStep1: 'To experience inbound call, you must setup your call center here. Or you can contact us for help if you need support',
  gettingStarted: 'Getting started',
  completeCheckListToUse: 'Complete these steps to start using system',
  inputInternalNumber: 'Input internal number',
  thisFieldRequired: 'This field is required',
  transferCall: 'Transfer',
  left: 'Remaining',
  minutesCallOut: 'minutes to call',
  exceedQuota: 'Your account has exceeded the quota',
  voiceNotReady: 'Voice is not ready',
  callDetail: 'Call detail',
  callStatus: 'Status',
  callDuration: 'Duration',
  seconds: 'seconds',
  today: 'Today',
  yesterday: 'Yesterday',
  thisweek: 'This week',
  lastweek: 'Last week',
  thismonth: 'This month',
  lastmonth: 'Last month',
  noCall: 'You haven\'t made any calls at this time',
  all: 'All',
  missed: 'Missed',
  profile: 'User profile',
  switchCompany: 'Switch company',
  changeLanguage: 'Change language'
};
export default lang;
