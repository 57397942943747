import React, { useEffect, useState } from 'react';
import { ChatWidget } from '@antbuddy-jsc/chat-widget';
import DashboardPage from './components/DashboardPage';
import VoiceEventLogger from '../../components/VoiceEventLogger';
import { useVoiceDispatch } from '../../context/VoiceContext';
import { useUserState } from '../../context/UserContext';
import { ToastContainer } from 'react-toastify';
import './index.scss';
import Cookies from 'js-cookie';

export default function Dashboard(props) {
  const [debugMode, setDebugMode] = useState(true);
  const voiced = useVoiceDispatch();
  const { user } = useUserState();
  let debug = debugMode;

  const keyDownHandler = (e) => {
    if (((e.ctrlKey && e.altKey) || (e.ctrlKey && e.metaKey)) && e.code === 'KeyV') {
      debug = !debug;
      setDebugMode(!debug);
    }
  };

  const addKeyEventHandler = () => {
    document.addEventListener('keydown', keyDownHandler, false);
  };

  useEffect(() => {
    const credential = Cookies.get('credential', { domain: Cookies.get('rootDomain') });
    if (credential) {
      var voiceCredential = JSON.parse(credential);
      voiced({ type: 'INIT', config: voiceCredential.config, dispatch: voiced });
    }

    addKeyEventHandler();
    return () => {
      document.removeEventListener('keydown', keyDownHandler, false);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <ToastContainer />
      <DashboardPage />
      <VoiceEventLogger debugMode={debugMode} />
      {user &&
        <ChatWidget
        // `accountId` is used instead of `token` in older versions
        // of the @antbuddy-jsc/chat-widget package (before v1.2.x).
        // You can delete this line if you are on the latest version.
        // accountId="cd23238c-0dd2-4f9e-9ce8-683f1df68dbd"
          token="cd23238c-0dd2-4f9e-9ce8-683f1df68dbd"
          inbox="61f0daf0-fe6e-4987-bd34-ec14720c3d67"
          title="Chào mừng bạn đến với AntBuddy.com"
          subtitle=" Hãy nhắn tin với chúng tôi!"
          primaryColor="#33358c"
          greeting="Xin chào! Bạn cần AntBuddy trợ giúp gì?"
          newMessagePlaceholder="Bắt đầu nhập..."
          showAgentAvailability={false}
          agentAvailableText="Chúng tôi đang online!"
          agentUnavailableText="Chúng tôi đang vắng mặt."
          requireEmailUpfront={false}
          requireNameUpfront={false}
          requirePhoneUpfront={false}
          iconVariant="outlined"
          baseUrl="https://chat-sdk.antbuddy.com"
          iframeUrlOverride="https://chat-widget.antbuddy.com"
          customIconUrl="https://s3.kstorage.vn/storage/logo_vi.png"
          // Optionally include data about your customer here to identify them
          customer={{
            name: user.name,
            email: user.email,
            external_id: user.key
          }}
        />}
    </>
  );
};
