var HCall = function(session, config, helper) {
  if (arguments.length === 0) return;

  this.session = session;
  this.config = config;
  this.helper = helper;
  this.__registerCallbacks();
}

HCall.prototype.hangup = function() {
  this.session.bye();
}

HCall.prototype.dtmf = function(value) {
  var reg = new RegExp(/^[0-9*#]/);
  if (reg.test(value)) {
    this.session.dtmf(value);
  }
}

HCall.prototype.mute = function(options) {
  // this.session.mute(options);
}

HCall.prototype.unmute = function(options) {
  // this.session.unmute(options);
}

HCall.prototype.isMuted = function() {
  return this.session.mediaHandler.isMuted();
}

HCall.prototype.hold = function(options) {
  this.session.hold(options);
}

HCall.prototype.unhold = function(options) {
  this.session.unhold(options);
}

HCall.prototype.isHold = function() {
  return this.session.isOnHold();
}

HCall.prototype.transfer = function(target, options) {
  this.session.refer(target, options);
}

HCall.prototype.__registerCallbacks = function() {
  var self = this;
  var config = self.config;
  var phone = config.phone;
  var ringbackTimeout = null;

  self.session.on('muted', function() {
    phone.onMuted(self);
  });

  self.session.on('unmuted', function() {
    phone.onUnmuted(self);
  });

  self.session.on('hold', function() {
    phone.onHold(self);
  });

  self.session.on('unhold', function() {
    phone.onUnhold(self);
  });

  self.session.on('bye', function() {
    self.helper.stop();
    phone.onHangup(self, 'bye');
  });

  self.session.on('dtmf', function(value) {
    phone.onDtmf(self, value);
  });

  self.session.on('accepted', function() {
    self.helper.stop();
    phone.onInprogress(self);
  });

  self.session.on('failed', function(request) {
    self.helper.stop();
    console.log(request.reasonPhrase, 'reason');
    if ([486, 480].indexOf(request.status_code) !== -1 && self.config.sounds.busyTone) {
      self.helper.play(self.config.sounds.busyTone);
      setTimeout(function() {
        self.helper.stop();
      }, 2000);
    }

    phone.onError(self.session, request);
  });

  self.session.on('rejected', function(response, cause) {
    self.helper.stop();
    phone.onHangup(self, 'rejected', cause);
  });

  self.session.on('cancel', function() {
    self.helper.stop();
    phone.onHangup(self, 'cancel');
  });

  self.session.on('terminated', function() {
    phone.onHangup(self, 'terminated');
  });

  self.session.on('refer', function() {
    phone.onTransfer(self, 'refer');
  });

  self.session.on('progress', function(response) {
    // Ref: https://wiki.freeswitch.org/wiki/180_vs._183_vs._Early_Media
    // Dial to mobile, only receive 183 (without 180)
    // Dial to internal system, receive both 180 and 183
    if (response.status_code === '180') {
      ringbackTimeout = setTimeout(function() {
        self.helper.play(self.config.sounds.outgoingTone);
        ringbackTimeout = null;
      }, 1000);
      return;
    }
    if (response.status_code === '183') {
      clearTimeout(ringbackTimeout);
      self.helper.stop(self.config.sounds.outgoingTone);
    }
  });

  self.session.on('trackAdded', function() {
    console.log('TRACK_ADDED');
    var peer = self.session.sessionDescriptionHandler.peerConnection;
    var playerRemote = document.getElementById('playerRemote');
    var remoteStream = new window.MediaStream();

    peer.getReceivers().forEach(function(receiver) {
      remoteStream.addTrack(receiver.track);
    });

    if (typeof playerRemote.srcObject !== 'undefined') {
      playerRemote.srcObject = remoteStream;
    } else if (typeof playerRemote.mozSrcObject !== 'undefined') {
      playerRemote.mozSrcObject = remoteStream;
    } else if (typeof playerRemote.src !== 'undefined') {
      playerRemote.src = URL.createObjectURL(remoteStream);
    } else {
      console.log('Error attaching stream to element.');
    }
    playerRemote.play();
  });
}

export default HCall
