import React from 'react';
import { useUserState } from '../../context/UserContext';
import './index.scss';

export default function({ ...props }) {
  const { user } = useUserState();

  return (
    <span className="stl-fullname"><span {...props}>{user && user.name}</span></span>
  )
}
