import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import { AuthenticateProvider } from './context/AuthenticateContext';
import { UserProvider } from './context/UserContext';
import { LayoutProvider } from './context/LayoutContext';
import { VoiceProvider } from './context/VoiceContext';
import _ from 'lodash';

import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-toastify/dist/ReactToastify.min.css';
import '@material/react-material-icon/dist/material-icon.css';
import './constant/scss/common.scss';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/us/translation'
import translationVI from './locales/vn/translation';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      us: {
        translation: translationEN
      },
      vn: {
        translation: translationVI
      }
    },
    lng: 'vn', // if you're using a language detector, do not define the lng option
    fallbackLng: 'vn',

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

_.deepMerge = deepMerge;

function deepMerge(object, source) {
  return _.mergeWith(object, source, function(objValue, srcValue) {
    if (_.isObject(objValue) && srcValue) {
      return deepMerge(_.cloneDeep(objValue), _.cloneDeep(srcValue));
    }

    if (_.isInteger(objValue)) {
      return objValue + srcValue;
    }

    return srcValue;
  });
}

ReactDOM.render(
  <AuthenticateProvider>
    <UserProvider>
      <LayoutProvider>
        <VoiceProvider>
          <App />
        </VoiceProvider>
      </LayoutProvider>
    </UserProvider>
  </AuthenticateProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
