import { call } from './call';
import { toast } from 'react-toastify';

function withRestrictCall({ isDisableCall, t }) {
  if (isDisableCall && !toast.isActive('mic-error')) {
    toast.error(t('pleaseCheckYourMicro'), {
      autoClose: 3000,
      hideProgressBar: true,
      position: toast.POSITION.BOTTOM_CENTER,
      toastId: 'mic-error'
    });
    return;
  }

  return call;
}

export { withRestrictCall };
