import { Menu, Transition } from '@headlessui/react'
import { withRouter } from 'react-router-dom';
import React, { Fragment, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import UserIcon from './icons/user-icon.svg';
import ChangeCompanyIcon from './icons/change-company.svg';
import LanguageIcon from './icons/language.svg';
import { useTranslation } from 'react-i18next';
import { useLayoutDispatch } from '../../context/LayoutContext';
import { useUserState } from '../../context/UserContext';

function UserDropdown({ history }) {
  const [shouldShowSwitchCompany, setShouldShowSwitchCompany] = useState(false);
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  });
  const { t } = useTranslation();
  const { user } = useUserState();
  const layoutd = useLayoutDispatch();

  const nextHistory = (pathName) => {
    if (isTabletOrMobileDevice) {
      layoutd({ type: 'TOGGLE_SIDEBAR' });
    }
    history.push({ pathname: pathName });
  };

  useEffect(() => {
    if (user) {
      if (user.listOrgs && user.listOrgs.length > 1) {
        setShouldShowSwitchCompany(true);
      }
    }
  }, [user]);

  return (
    <div className="w-56 text-left">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex w-full justify-center rounded-md pl-[15px] text-sm font-medium  focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
            <div className='__user-name'>
              {user?.name}
            </div>
            <svg className="ml-2 w-4 h-4 mt-[10px] pointer" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" /></svg>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg z-50 ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1">
              <Menu.Item>
                {() => (
                  <button
                    className="text-black group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-slate-200"
                    onClick={() => window.open(`https://id.${process.env.REACT_APP_HOST}/auth/realms/production.antbuddy.com/account/`, 'blank')}
                  >
                    <img src={UserIcon} className="mr-2 h-4 w-4" />
                    {t('profile')}
                  </button>
                )}
              </Menu.Item>
              {shouldShowSwitchCompany && (
                <Menu.Item>
                  {() => (
                    <button
                      className="text-black group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-slate-200"
                      onClick={() => layoutd({ type: 'SET', value: { isShowSwitchCompany: true } })}
                    >
                      <img src={ChangeCompanyIcon} className="mr-2 h-4 w-4" />
                      {t('switchCompany')}
                    </button>
                  )}
                </Menu.Item>
              )}
            </div>
            <div className="px-1 py-1">
              <Menu.Item>
                {() => (
                  <button
                    className="text-black group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-slate-200"
                    onClick={() => nextHistory('/app/settings')}
                  >
                    <img src={LanguageIcon} className="mr-2 h-4 w-4" />
                    {t('changeLanguage')}
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}

export default withRouter(UserDropdown);
