import React from 'react';
import MaterialIcon from '@material/react-material-icon';
import VoiceStatus from './components/VoiceStatus';

import { useVoiceState } from '../../../../context/VoiceContext';
import UserFullname from '../../../UserFullname/UserFullname';

export default function() {
  const { voiceState } = useVoiceState();
  return (
    <div className="statusbar-wrapper">
      <div className="statusbar-wrapper__status-icon">
        <MaterialIcon icon="fiber_manual_record" className={voiceState === 'registered' ? '_status-ready' : ''} />
        <UserFullname />
      </div>
      <div className={'statusbar-wrapper__status-text ' + (!voiceState ? 'bg-secondary' : '') + ((voiceState === 'failed' || voiceState === 'unregistered') ? 'bg-danger' : '')}>
        <MaterialIcon icon="perm_identity" />
        <VoiceStatus />
      </div>
    </div>
  );
}
