import HPhone from './HPhone';
import HMessage from './HMessage';
import HIncomingCall from './HIncomingCall';
import * as SIP from 'sip.js';

const RApp = function(config, helper) {
  const self = this;

  const {
    sip,
    phone,
    registration,
    sounds
  } = config;

  const UA = new SIP.UA(sip);
  const hPhone = new HPhone(config, UA, helper);
  const hMessage = new HMessage(config, UA, helper);

  const retryTimeoutSeconds = 5;
  let retryingConnection = null;

  const notifyErrorSocket = () => {
    console.log('WebSocket have problems ...');
    if (retryTimeoutSeconds > 0) {
      if (retryingConnection) clearTimeout(retryingConnection);
      retryingConnection = setTimeout(() => {
        // eslint-disable-next-line no-undef
        if (UA.transport.ws.readyState !== WebSocket.OPEN) {
          UA.logger.warn('WebSocket Connection timeout');
          console.log('WebSocket Connection timeout');
          UA.transport.ws.close();
        }
      }, retryTimeoutSeconds * 1000)
    }
  }
  const closeRetry = () => {
    if (retryingConnection) clearTimeout(retryingConnection);
  }
  /* Retry
  */
  var _Transports = UA.transport;
  var isConnected = _Transports.isConnected(); var isConnecting = !_Transports.isConnected(); var saveStated = isConnected;
  try {
    setTimeout(
      setInterval(() => {
        var isConnected = _Transports.isConnected();
        if (isConnected !== saveStated) { console.log('status fetch Websocket: ', isConnected); }
        if (isConnected) {
          if (isConnecting) {
            console.log('voice connected');
            isConnecting = false;
          }
        } else {
          isConnecting = true;
          console.log('send reconnect ... ');
          _Transports.reconnect();
        }
        saveStated = isConnected;
      }, 3000),
      3000);
  } catch (err) {
    console.log(err);
  }

  UA.on('connecting', () => { notifyErrorSocket(); });

  UA.on('connected', () => { registration.onConnected(); closeRetry(); });
  UA.on('registered', (response) => { registration.onRegistered(response); });
  UA.on('unregistered', (response, cause) => { registration.onUnregistered(response, cause); });
  UA.on('registrationFailed', (response, cause) => { registration.onRegisterFailed(response, cause); });
  UA.on('error', (error) => { phone.onErrorCall(error) });
  UA.on('invite', (session) => {
    helper.play(sounds.incomingTone);
    const call = new HIncomingCall(session, config, helper);
    phone.onIncomingCall(call);
  });

  self.config = config;
  self.UA = UA;
  self.Phone = hPhone;
  self.Message = hMessage;
};

RApp.prototype.connect = function() {
  this.UA.start();
}

RApp.prototype.disconnect = function() {
  this.UA.stop();
}

RApp.prototype.isRegistered = function () {
  return this.UA.isRegistered();
}

RApp.prototype.register = function() {
  this.UA.register();
}

RApp.prototype.unregister = function() {
  this.UA.unregister();
}

// TODO:
RApp.prototype.message = function() {}
RApp.prototype.subscribe = function() {}
RApp.prototype.request = function() {}

export default RApp
