/* global _ */
import React from 'react';
import './index.scss';
import MaterialIcon from '@material/react-material-icon';
import { useLayoutDispatch } from '../../context/LayoutContext';
import { useVoiceDispatch, registerVoice } from '../../context/VoiceContext';
import { withRouter } from 'react-router-dom';
import { signOut, useAuthenticateDispatch } from '../../context/AuthenticateContext';
import { useUserState, useUserDispatch, prefixUrl } from '../../context/UserContext';
import { initAliceTrial } from '../AliceTrial/AliceTrial.method';
import SwitchAccount from '../SwitchAccount';
import AntCRMLogo from './logo/antcrm.svg';
import AntBotLogo from './logo/antbot.svg';
import { useMediaQuery } from 'react-responsive';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import UserDropdown from '../../components/UserDropdown';

const MobileSidebar = ({ isSidebarOpened, history }) => {
  const { t } = useTranslation();
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)'
  });
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  });
  const userd = useUserDispatch();
  const authenticateDispatch = useAuthenticateDispatch();
  const layoutd = useLayoutDispatch();
  const voiceDispatch = useVoiceDispatch();
  const { user, org, domain } = useUserState();

  const toggleSidebar = () => {
    layoutd({ type: 'TOGGLE_SIDEBAR' });
  }

  const nextHistory = (pathName) => {
    if (isTabletOrMobileDevice) {
      layoutd({ type: 'TOGGLE_SIDEBAR' });
    }
    history.push({ pathname: pathName });
  }

  const openCallCenter = () => {
    const abDomain = process.env.REACT_APP_DOMAIN;
    const [protocol, hostname] = abDomain.split('://');
    const origin = `${protocol}://${domain}.${hostname}`;
    const destinationPage = Roles.isManager(userRole) ? `${origin}/app#!/mall/callcenter/configuration` : `${origin}/app#!/mall/callcenter/callback`;
    window.name = 'antring';
    return window.open(destinationPage, 'antbuddy');
  }

  const handleSignout = () => {
    signOut(authenticateDispatch);
    layoutd({ type: 'TOGGLE_SIDEBAR' });
  }

  const onSelectOrg = (selected, user) => {
    registerVoice(user, selected, voiceDispatch);
    userd({ type: 'REFRESH_CUR_DOMAIN' });
  };

  const isAndroid = (navigator.userAgent.search('Android') !== -1);
  const isIOS = (navigator.userAgent.search('iPhone') !== -1);
  const userAvatar = user ? prefixUrl(user.avatar) : '';

  const [userRole, setUserRole] = React.useState('');
  const [userExt, setUserExt] = React.useState('');
  // @deprecated
  const Roles = {
    isAdmin: (role) => {
      return role && role.indexOf('manager') >= 0;
    },
    isOwner: (role) => {
      return role && role.indexOf('owner') >= 0;
    },
    isManager: (role) => {
      return (role && role.indexOf('owner') >= 0) || (role && role.indexOf('manager') >= 0);
    }
  };

  const openAccount = () => {
    window.open(`https://id.${process.env.REACT_APP_HOST}/auth/realms/production.antbuddy.com/account/`, '_id');
  }

  const handleClickAlice = () => {
    // var alreadyInitAlice = _.get(org, 'metadata.initialize.provision_alice');
    // var alreadyCreateTrialAlice = _.get(org, 'metadata.initialize.create_trial_alice');
    var alreadyStartTrialAlice = _.get(org, 'metadata.initialize.start_trial_alice');
    if (alreadyStartTrialAlice) {
      window.open(`${process.env.REACT_APP_ALICE_DOMAIN}/onboarding`, 'alice')
    } else {
      if (!Roles.isOwner(userRole)) {
        /* require admin */
        toast.warn(t('needAdminToContinue'), {
          autoClose: true,
          hideProgressBar: true,
          position: toast.POSITION.TOP_CENTER
        });
      } else {
        layoutd({ type: 'TOGGLE_SIDEBAR' });
        initAliceTrial(layoutd, userd, history, { org, user });
      }
    }
  };

  React.useEffect(() => {
    if (user && org && domain) {
      const org = _.find(user.listOrgs, ['domain', domain]);
      if (isDesktopOrLaptop) {
        layoutd({ type: 'SET', value: { isSidebarOpened: true } });
      } else if (isTabletOrMobileDevice) {
        layoutd({ type: 'SET', value: { isSidebarOpened: false } });
      }
      if (org && org.key) {
        const userOrg = _.find(user.orgs, ['orgKey', org.key]);
        if (userOrg && userOrg.role) {
          setUserRole(userOrg.role);
          setUserExt(userOrg.anttel.ext.join(', '));
        }
      }
    }
  }, [user, org, domain, isDesktopOrLaptop, isTabletOrMobileDevice]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={'sidebar-wrapper-mobile ' + (isSidebarOpened ? 'active' : '')}>
      {isTabletOrMobileDevice ? <div className='__overlay' onClick={toggleSidebar} /> : ''}
      <div className='__menu'>
        <div className='__user-info'>
          <div className='__user-avatar pointer' onClick={() => openAccount()}>
            <img src={userAvatar} alt='' />
          </div>
          <div className='justify-user-content'>
            <UserDropdown />
            <div className='flex'>
              <SwitchAccount onSelectOrg={onSelectOrg} />
              {
                userExt.length !== 0
                  ? (
                    <div className='__user-ext mt-[3px]'>
                      <MaterialIcon icon='phone_forwarded' className='__ext-icon' />
                      <p className='__ext-number'>{userExt}</p>
                    </div>
                  ) : null
              }
            </div>

          </div>
        </div>
        <div className='__parent-title __ant-ring'>
          {t('antRingCallCenter')}
        </div>
        <div className='nav-link p-2 __child __bt' onClick={() => nextHistory('/app/dashboard')}>
          <div className='__sidebar-calling'>
            <MaterialIcon icon='dialpad' />
            <span>{t('dialpad')}</span>
          </div>
        </div>
        <div className='nav-link p-2 __child __bt' onClick={() => nextHistory('/app/history')}>
          <div className='__sidebar-recent'>
            <MaterialIcon icon='library_books' />
            <span>{t('callHistory')}</span>
          </div>
        </div>
        <div className='nav-link p-2 __child' onClick={() => openCallCenter()}>
          <div className='__sidebar-mall'>
            <MaterialIcon icon='call' />
            <span>{t('callCenterManagement')}</span>
          </div>
        </div>
        {isAndroid ? (
          <div className='nav-link p-2 __child' onClick={() => window.open('https://play.google.com/store/apps/details?id=com.antbuddy', '_blank')}>
            <div className='__sidebar-download-app'>
              <MaterialIcon icon='get_app' />
              <span>{t('downloadAntBuddyMobile')}</span>
            </div>
          </div>)
          : isIOS ? (
            <div className='nav-link p-2 __child' onClick={() => window.open('https://apps.apple.com/vn/app/antbuddy-omnichannel-center/id1501120012', '_blank')}>
              <div className='__sidebar-download-app'>
                <MaterialIcon icon='get_app' />
                <span>{t('downloadAntBuddyMobile')}</span>
              </div>
            </div>
          ) : (
            <div className='nav-link p-2 __child' onClick={() => window.open('https://www.antbuddy.com/tai-ung-dung', 'antbuddyhome')}>
              <div className='__sidebar-download-app'>
                <MaterialIcon icon='get_app' />
                <span>{t('downloadAntBuddyPC')}</span>
              </div>
            </div>
          )}

        <div className="__parent-title __other-products">
          {t('otherOurProducts')}
        </div>
        <div className='nav-link p-2 __child __bt' onClick={() => window.open(`https://${domain}.${process.env.REACT_APP_BEE_DOMAIN.replace(/http[s]?:\/\//, '')}/auth/abid`, 'beeiq')}>
          <div className='__sidebar-antcrm'>
            <img alt="antcrm" className="material-icons" src={AntCRMLogo} width="20px;" style={{ marginLeft: 1 }} />
            <span>AntCRM</span>
          </div>
        </div>
        <div className='nav-link p-2 __child __bt' onClick={() => handleClickAlice()}>
          <div className='__sidebar-antbot'>
            <img alt="antbot" className="material-icons" src={AntBotLogo} width="26px" style={{ marginTop: -4, marginLeft: -5 }} />
            <span>AntBOT</span>
          </div>
        </div>

        <div className="__parent-title __gap" />
        <div className='nav-link p-2 __child' onClick={handleSignout}>
          <div className='__sidebar-signout'>
            <MaterialIcon icon='phonelink_off' />
            <span>{t('logout')}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(MobileSidebar)
