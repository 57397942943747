import React from 'react';
import _ from 'lodash';
import { useUserState } from '../../context/UserContext';
import { useLayoutDispatch, useLayoutState } from '../../context/LayoutContext';
import ModalSwitchCompany from './ModalSwitchCompany';
import Utils from '../../utils';
import Cookies from 'js-cookie';
import './index.scss';

export default function ({ onSelectOrg }) {
  const { user, domain } = useUserState();
  const { isShowSwitchCompany } = useLayoutState();
  const layoutd = useLayoutDispatch();

  const sendOnSelected = (selected) => {
    onSelectOrg(selected, user);
  };

  const setShowing = (flag) => {
    layoutd({ type: 'SET', value: { isShowSwitchCompany: flag } });
  }

  React.useEffect(() => {
    const credential = Cookies.get('credential', { domain: Cookies.get('rootDomain') });
    if (!credential && user) {
      var activeOrgs = _.filter(user.orgs, ['status', 'active']);
      if (activeOrgs && activeOrgs.length === 1) {
        const selected = Utils.assignSelectedOrg(user, user.orgs[0].orgKey);
        onSelectOrg(selected, user);
      } else {
        setShowing(true);
      }
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <div className="switch-account-wrapper">
        <span className="__domain text-muted" title={domain}>{domain}</span>
      </div>
      {
        isShowSwitchCompany &&
          <ModalSwitchCompany
            user={user}
            onClose={() => setShowing(false)}
            onSelectOrg={(selected) => sendOnSelected(selected)}
          />
      }
    </>
  )
}
