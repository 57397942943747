import {
  axiosWithBearer,
  tokenDoesNotExist,
  withSubdomain,
  refreshTokenDoesNotExist
} from '../apiHelper';

import Cookies from 'js-cookie';

const rootUrl = process.env.REACT_APP_DOMAIN;

function getUserInfo() {
  if (tokenDoesNotExist()) return Promise.reject(new Error('NOT_FOUND_TOKEN'));
  return axiosWithBearer().get(`${rootUrl}/api/users/me`);
}

function getUsers(params, subdomain = getSubdomain()) {
  if (tokenDoesNotExist()) return Promise.reject(new Error('TOKEN NOT FOUND'));
  return axiosWithBearer().get(withSubdomain(`${rootUrl}/api/users`, subdomain), { params: params });
}

function getSubdomain() {
  return Cookies.get('domain');
}

function updateUser(user, subdomain = getSubdomain()) {
  if (tokenDoesNotExist()) return Promise.reject(new Error('TOKEN NOT FOUND'));
  return axiosWithBearer().put(withSubdomain(`${rootUrl}/api/users/${user.key}`, subdomain), user);
}

function refreshToken() {
  if (refreshTokenDoesNotExist()) return Promise.reject(new Error('NOT_FOUND_REFRESH_TOKEN'))
  return axiosWithBearer().post(`${rootUrl}/auth/refresh_access_token`, {
    refreshToken: Cookies.get('refreshToken')
  });
}

export { getUserInfo, refreshToken, getUsers, updateUser };
