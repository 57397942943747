import React, { useState, useEffect, useRef } from 'react';
import CallPad from './components/CallPad';
import CallFunctions from './components/CallFunctions';
import CallButtons from './components/CallButtons';
import RejectButton from '../buttons/RejectButton';
import ShowDialpadButton from '../buttons/ShowDialpadButton';
import MuteButton from '../buttons/MuteButton';
// import HoldButton from '../buttons/HoldButton';
import TransferButton from '../buttons/TransferButton';
import DtmfScreen from './components/DtmfScreen';
import TransferScreen from './components/TransferScreen';
import { useVoiceState } from '../../../../context/VoiceContext';

export default function InProgress() {
  const [isShowDtmf, setIsShowDtmf] = useState(false);
  const [isShowTransfer, setIsShowTransfer] = useState(false);
  const [timer, setTimer] = useState(0);
  const { callState } = useVoiceState();
  const callTimer = useRef(0);

  const showDtmf = () => {
    if (isShowTransfer) setIsShowTransfer(false);
    setIsShowDtmf(!isShowDtmf)
  };

  const showTranfer = () => {
    if (isShowDtmf) setIsShowDtmf(false);
    setIsShowTransfer(!isShowTransfer)
  };

  useEffect(() => {
    var id;
    if (callState === 'in_progress') {
      id = setInterval(() => {
        callTimer.current += 1;
        setTimer(callTimer.current);
      }, 1000);
    }
    return () => clearInterval(id);
  }, [callState]);

  return (
    <>
      <div className="dialpad-wrapper__handle-screen">
        {
          !isShowDtmf && !isShowTransfer &&
            <CallPad timer={timer} /> // TODO: fix props drill
        }
        {
          isShowDtmf &&
            <DtmfScreen />
        }
        {
          isShowTransfer &&
            <TransferScreen />
        }

        <CallFunctions>
          <ShowDialpadButton isShowDtmf={isShowDtmf} callback={showDtmf} />
          {/* <HoldButton /> */}
          <MuteButton />
          <TransferButton isShowTransfer={isShowTransfer} callback={showTranfer} />
        </CallFunctions>
        <CallButtons>
          <RejectButton left="calc(50% - 30px)" />
        </CallButtons>
      </div>
    </>
  );
}
