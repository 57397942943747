import React from 'react';
import DigitsMenu from '../../menu/DigitsMenu';
import { useCallMenuState, useCallMenuDispatch } from '../../../../../context/CallMenuContext';

const InputDTMF = () => {
  const { dtmfNumber } = useCallMenuState();
  return (
    <input type="text" disabled className="form-control dtmf-input" value={dtmfNumber} />
  )
}

export default function DtmfScreen() {
  const callMenuDispatch = useCallMenuDispatch();

  React.useEffect(() => {
    return () => {
      callMenuDispatch({ type: 'SET_DTMF', number: '' });
    };
  });

  return (
    <>
      <InputDTMF />
      <DigitsMenu
        menuType="dtmf"
        className="dialpad-wrapper__digits __white"
      />
    </>
  )
}
