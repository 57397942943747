import React, { useEffect, useState } from 'react';
import API from '../../apis';
import { Form, Modal, ListGroup } from 'react-bootstrap';
import {
  getUserExtension,
  getUserBio,
  getAvatarUrl,
  getUserName,
  getUserPhone
} from '@antbuddy-jsc/ab-utils/node';
import OnLoading from '../OnLoading';
import MaterialIcon from '@material/react-material-icon'
import { useLayoutState } from '../../context/LayoutContext';
import { useVoiceDispatch } from '../../context/VoiceContext';
import { withRestrictCall } from '../../services/withs';
import './index.scss';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

function MemberList({ onClickHide }) {
  const { t } = useTranslation();
  const [users, setUsers] = useState();
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userCall, setUserCall] = useState();
  const [searchKeyword, setSearchKeyword] = useState('');
  const { isDisableCall } = useLayoutState();
  const voiced = useVoiceDispatch()
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  });

  async function initializeMemberList() {
    try {
      setIsLoading(true);
      const { data: users } = await API.User.getUsers({ onlyActive: true, includeSearchKeyword: true });
      setUsers(users);

      setIsLoading(false);
    } catch (err) {
      console.error(err, '[get user list failed]');
      setIsLoading(false);
    }
  }

  const handleMakeCall = async (number) => {
    setShow(false);
    const call = withRestrictCall({ isDisableCall, t });
    if (call) {
      call(number, voiced);
    }
  };

  useEffect(() => {
    initializeMemberList();
  }, []);

  return (
    <div className="layout-container">
      <div className="">
        <div className="text-center font-semibold text-lg mt-[20px] mb-[20px] relative">
          {t('members')}
          <div className="absolute top-[2px] left-[7px] cursor-pointer" onClick={() => onClickHide && onClickHide()}>
            {isTabletOrMobileDevice && (<MaterialIcon icon="chevron_left" className="text-[30px]" />)}
            {!isTabletOrMobileDevice && (<MaterialIcon icon="chevron_right" className="text-[30px]" />)}
          </div>
        </div>
      </div>
      <div className="w-full">
        <div className="d-flex">
          <SearchIcon />
          <SearchBar searchKeyword={searchKeyword} setSearchKeyword={setSearchKeyword} />
        </div>
      </div>
      {!isLoading ? (
        <div className="mt-[10px] pb-[50px] mr-[-15px] overflow-scroll" style={{ height: 'calc(100vh - 190px)' }}>
          <List
            users={users} searchKeyword={searchKeyword} onPressUser={(user) => {
              setUserCall(user);
              setShow(true);
            }}
          />
        </div>
      ) : <OnLoading onShow={isLoading} />}
      <ModalCall userCall={userCall} show={show} setShow={setShow} onPhoneNumberPressed={(phone) => handleMakeCall(phone)} />
    </div>
  );
}

const SearchIcon = () => {
  return (
    <div className="search-icon">
      <MaterialIcon icon="search" />
    </div>
  )
};

const SearchBar = ({ searchKeyword, setSearchKeyword }) => {
  const { t } = useTranslation();
  return (
    <Form.Control type="text" placeholder={t('typeToSearchMember')} value={searchKeyword} onChange={event => setSearchKeyword(event.target.value)} />
  )
};

const List = ({ users, searchKeyword, onPressUser }) => {
  if (!users || !users.length) return null;
  return users.filter(user => {
    if (!searchKeyword) return true;
    if (user.searchKeyword) return user.searchKeyword.indexOf(searchKeyword) >= 0 || (user.anttel && user.anttel.ext && user.anttel.ext.includes(searchKeyword));
    return false;
  }).map((user) => {
    return (
      <ListItem key={user.key} user={user} onPressUser={() => onPressUser && onPressUser(user)} />
    )
  });
};

const ListItem = ({ user, onPressUser }) => {
  return (
    <div className="d-flex member-list-item" onClick={() => onPressUser && onPressUser()}>
      <Avatar src={getAvatarUrl(user)} />
      <div>
        <Name name={getUserName(user)} />
        <Bio bio={getUserBio(user)} />
      </div>
      <Extension extension={getUserExtension(user).join(', ')} />
    </div>
  );
};

const Avatar = ({ src }) => {
  return (
    <div className="avatar-wrapper">
      <img src={src} />
    </div>
  )
};

const ModalCall = ({ userCall, show, setShow, onPhoneNumberPressed }) => {
  const { t } = useTranslation();
  const handleClose = () => setShow(false);
  const userExtensions = getUserExtension(userCall);
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('call')} {getUserName(userCall)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ListGroup>
          <ListGroup.Item action onClick={() => onPhoneNumberPressed(userCall.username)} />
          {userExtensions && userExtensions.length ? userExtensions.map(extension => (
            <ListGroup.Item key={extension} action onClick={() => onPhoneNumberPressed(extension)}>{t('callInternal')} - {extension}</ListGroup.Item>
          )) : null}
          {getUserPhone(userCall) ? <ListGroup.Item action onClick={() => onPhoneNumberPressed(getUserPhone(userCall))}>{t('callMobile')} - {getUserPhone(userCall)}</ListGroup.Item> : null}
        </ListGroup>
      </Modal.Body>
    </Modal>
  )
};

const Name = ({ name }) => {
  return <div className="user-name">{name}</div>
};

const Extension = ({ extension }) => {
  if (!extension) return null;
  return (
    <div className="user-extension">
      <MaterialIcon icon="phone" className="phone-icon text-success" />
      <span className="text-extension text-success">{extension}</span>
    </div>
  );
};

const Bio = ({ bio }) => {
  return <div className="user-bio">{bio}</div>
};

export default MemberList;
