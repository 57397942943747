import React, { useState } from 'react';
import './index.scss';

export default function () {
  const [cost, setCost] = useState(0);
  const [, setShowModal] = useState(false);
  const calculateCost = (e) => {
    var minutes = e.target.value;
    var tempCost = minutes * 1000;
    setCost(tempCost);
  };

  const requestPayment = () => {
    setShowModal(true);
  };

  return (
    <>
      <div className="payment-wrapper">
        <div className="text-center m-2 font-weight-bold h4">Thanh toán</div>
        <div className="">02873000037</div>
        <div className="">Số phút còn lại: 0 phút</div>
        <div className="form-group">
          <label>Mua phút gọi</label>
          <input type="number" className="form-control" placeholder="Số phút" onChange={calculateCost} />
        </div>
        <div className="form-group">
          <label>Giá</label>
          <div className='input-group mb-3'>
            <input type='text' className='form-control' placeholder='Giá' disabled value={cost} />
            <div className='input-group-append'>
              <span className='input-group-text'>VND</span>
            </div>
          </div>
        </div>
        <div className="btn btn-block btn-success mobile-btn" onClick={requestPayment}>
          Chọn phương thức thanh toán
        </div>
      </div>
    </>
  );
}
