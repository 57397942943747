import React from 'react';
import MaterialIcon from '@material/react-material-icon'

export default function ActiveCallAvatar() {
  return (
    <div className="ca-avatar in-call">
      <MaterialIcon icon="account_circle" />
    </div>
  )
}
