var DEBUG = false;
function debug() {
  if (DEBUG) console.log(arguments);
}

function VoiceEventHandler(dispatch) {
  this.dispatch = dispatch;
}

VoiceEventHandler.prototype.onConnected = function(e) {
  debug('onConnected', e);
  this.dispatch({ type: 'CONNECTION', value: 'connected' });
}

VoiceEventHandler.prototype.onRegistered = function() {
  debug('onRegistered');
  this.dispatch({ type: 'REGISTRATION', value: 'registered' });
}

VoiceEventHandler.prototype.onUnregistered = function(response, cause) {
  debug('onUnregistered');
  this.dispatch({ type: 'REGISTRATION', value: 'unregistered' });
}

VoiceEventHandler.prototype.onRegisterFailed = function(response, cause) {
  debug('onRegisterFailed', response, cause);
  this.dispatch({ type: 'REGISTRATION', value: 'failed', response: response, cause: cause });
}

VoiceEventHandler.prototype.onIncomingCall = function(call) {
  debug('onIncomingCall', call);
  window.ABV.activeCall = call;
  window.ABV.accept = false;
  this.dispatch({ type: 'CALL_SIGNAL', value: 'incoming' });
}

VoiceEventHandler.prototype.onCallInProgress = function(call) {
  debug('onCallInProgress', call);
  window.ABV.inCall = true;
  this.dispatch({
    type: 'CALL_SIGNAL',
    value: 'in_progress',
    call: call
  });
}

VoiceEventHandler.prototype.onCallHangup = function(call, reason, cause) {
  debug('onCallHangup', call, reason, cause);
  this.dispatch({
    type: 'CALL_SIGNAL',
    value: 'hangup',
    call: call,
    reason: reason,
    cause: cause
  });
}

VoiceEventHandler.prototype.onCallError = function(call, request) {
  debug('onCallError', call, request);
  this.dispatch({
    type: 'CALL_SIGNAL',
    value: 'error'
  });
}

VoiceEventHandler.prototype.onCallMuted = function(call) {
  debug('onCallMuted', call);
  this.dispatch({
    type: 'CALL_SIGNAL',
    value: 'muted'
  });
}

VoiceEventHandler.prototype.onCallUnmuted = function(call) {
  debug('onCallUnmuted', call);
  this.dispatch({
    type: 'CALL_SIGNAL',
    value: 'unmuted'
  });
}

VoiceEventHandler.prototype.onCallTransfer = function(call, reason) {
  debug('onCallTransfer', call, reason);
  this.dispatch({
    type: 'CALL_SIGNAL',
    value: 'transfer'
  });
}

VoiceEventHandler.prototype.onCallHold = function(call) {
  debug('onCallHold', call);
  this.dispatch({
    type: 'CALL_SIGNAL',
    value: 'hold'
  });
}

VoiceEventHandler.prototype.onCallUnhold = function(call) {
  debug('onCallUnhold', call);
  this.dispatch({
    type: 'CALL_SIGNAL',
    value: 'unhold'
  });
}

export default VoiceEventHandler
