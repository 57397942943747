import React from 'react';

export default function CallFunctions({ children }) {
  return (
    <div className="dialpad-wrapper__call-functions">
      <div className="btn-group">
        {children}
      </div>
    </div>
  )
}
