import React from 'react';
import moment from 'moment';

const log = (msg) => {
  return (
    <div><b>{moment().format('DD-MM-YYYY HH:mm:ss') + ' '}</b>{msg}</div>
  )
}

const getCallId = (call) => {
  var session = call.session;
  var callId = session.id;
  return callId;
}

const getCallNumber = (call) => {
  var session = call.session;
  return session.remoteIdentity.uri.user;
}

const getTalkTime = (call) => {
  var session = call.session;
  if (session.startTime && session.endTime) {
    var begin = moment(session.startTime);
    var end = moment(session.endTime);
    var diff = end.diff(begin);
    var seconds = moment.duration(diff).asSeconds();
    return seconds;
  }
  return 0;
}

export {
  log,
  getCallId,
  getCallNumber,
  getTalkTime
}
