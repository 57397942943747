import HCall from './HCall';

var HIncomingCall = function(session, config) {
  HCall.apply(this, arguments);
}

// inheritance from HCall
HIncomingCall.prototype = new HCall();

// function accept(options)
// usage: call.accept({media: {audio: true, video: true}, callbacks: {}})
// -----------------------------------------
//
// Accept a call
HIncomingCall.prototype.accept = function(options) {
  this.session.accept(options);
}

// function reject()
// usage: call.reject()
// -----------------------------------------
// Reject a call
HIncomingCall.prototype.reject = function() {
  var options = {
    statusCode: 486
  };
  this.session.reject(options);
}

export default HIncomingCall
