import React from 'react';
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom';
import { useAuthenticateState, useAuthenticateDispatch, signOut } from '../../context/AuthenticateContext';
import { getCurrentOrg, useUserDispatch } from '../../context/UserContext';
import API from '../../apis';
import { useTranslation } from 'react-i18next';

export default function ({ defaultPage, layout }) {
  const { i18n } = useTranslation();
  const { isAuthenticated } = useAuthenticateState();
  const authenticateDispatch = useAuthenticateDispatch();
  const userDispatch = useUserDispatch();

  React.useEffect(initializeAuthHandler.bind(null, userDispatch), []);

  return (
    <HashRouter>
      <Switch>
        <Route exact path="/" render={() => <Redirect to={defaultPage} />} />
        <PrivateRoute path="/app" component={layout} /> {/* isAuthenticated: true */}
        <PublicRoute path="/login" component={() => signOut(authenticateDispatch)} /> {/* isAuthenticated: false */}
      </Switch>
    </HashRouter>
  )

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest} render={
          (props) =>
            isAuthenticated
              ? (React.createElement(component, props))
              : (<Redirect to={{ pathname: '/login', state: { from: props.location } }} />)
        }
      />
    )
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest} render={
          (props) =>
            isAuthenticated
              ? (<Redirect to={{ pathname: '/', state: { from: props.location } }} />)
              : (React.createElement(component, props))
        }
      />
    )
  }

  function initializeAuthHandler(dispatch) {
    async function firstInit() {
      try {
        const registerInfo = JSON.parse(window.localStorage.getItem('registerInfo'));
        if (registerInfo && registerInfo.userInfo && registerInfo.orgInfo) {
          window.localStorage.removeItem('registerInfo');
          API.Auth.setupVoice(registerInfo.userInfo, registerInfo.orgInfo, 'other');
        }
      } catch (e) {
        console.error(e);
      }

      try {
        await API.User.refreshToken();
      } catch (e) {
        console.error(e);
        signOut(authenticateDispatch);
      }

      try {
        const data = await API.User.getUserInfo();
        const user = data.data;
        const { org } = getCurrentOrg(user);
        const langCode = org.settings.language === 'en' ? 'us' : 'vn';
        i18n.changeLanguage(langCode);

        dispatch({ type: 'ASSIGN', user: user });
        dispatch({ type: 'REFRESH_CUR_DOMAIN' });
      } catch (e) {
        console.error(e);
      };
    }

    firstInit();
  }
}
