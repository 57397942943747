import AntbuddyRTC from './webrtc/AntbuddyRTC';
import Helper from './webrtc/Helper';
import * as SIP from 'sip.js';
import _ from 'lodash';
import VoiceEventHandler from './webrtc/VoiceEventHandler';
import * as configuration from './webrtc/Configuration';
import incomingTone from './webrtc/sounds/incoming.mp3';
import outgoingTone from './webrtc/sounds/outgoing.mp3';
import busyTone from './webrtc/sounds/busy.mp3';

// Antbuddy Voice
window.ABV = {
  app: null,
  activeCall: null,
  stream: null,
  inCall: false,
  state: null
};

function initVoice({
  wsServers,
  displayName,
  uri,
  authorizationUser,
  password,
  stunServers
}, voiced) {
  const handler = new VoiceEventHandler(voiced);
  if (window.ABV.app) {
    const hostnameUri = _.get(window.ABV.app, 'UA.configuration.hostportParams');
    if (hostnameUri === _.nth(uri.split('@'), 1)) return;
  }

  const sip = {
    displayName: displayName,
    uri: uri,
    transportOptions: {
      wsServers: wsServers,
      connectionTimeout: 5, /* In seconds, maximum time for WebSocket initial connection */
      reconnectionTimeout: 60, /* time wait between request reconnect Socket */
      maxReconnectionAttempts: 50 /* 50 times reconnect to a WebSocket when the connection drops */
    },
    authorizationUsersip: authorizationUser,
    password: password,
    rel100: SIP.C.supported.SUPPORTED,
    userAgentString: `Antbuddy-Onboarding/${SIP.version}`,
    sessionDescriptionHandlerOptions: {
      constraints: {
        audio: true,
        video: false
      }
    },
    registerOptions: {
      expires: 300
    },
    log: {
      level: 0
    },
    allowOutOfDialogRefers: true
  };

  const config = {
    sip: sip,
    registration: {
      onConnected: handler.onConnected.bind(handler),
      onRegistered: handler.onRegistered.bind(handler),
      onUnregistered: handler.onUnregistered.bind(handler),
      onRegisterFailed: handler.onRegisterFailed.bind(handler)
    },
    phone: {
      onIncomingCall: handler.onIncomingCall.bind(handler),
      onInprogress: handler.onCallInProgress.bind(handler),
      onHangup: handler.onCallHangup.bind(handler),
      onError: handler.onCallError.bind(handler),
      onMuted: handler.onCallMuted.bind(handler),
      onUnmuted: handler.onCallUnmuted.bind(handler),
      onTransfer: handler.onCallTransfer.bind(handler),
      onHold: handler.onCallHold.bind(handler),
      onUnhold: handler.onCallUnhold.bind(handler)
    },
    sounds: {
      incomingTone: incomingTone,
      busyTone: busyTone,
      outgoingTone: outgoingTone
    }
  };

  var helper = new Helper();
  if (!window.ABV.app) {
    window.ABV.app = AntbuddyRTC.createApp(config, helper); // TODO: refactor
  } else {
    window.ABV.app.unregister();
    window.ABV.app.disconnect();
    window.ABV.app = AntbuddyRTC.createApp(config, helper);
  }
}

function call (phoneNumber, voiced) {
  return new Promise((resolve, reject) => {
    var app = window.ABV.app;
    // Resolve +84, ...
    var activeCall = app.Phone.call(phoneNumber, configuration.outgoingOptions);
    window.ABV.activeCall = activeCall;
    if (voiced) {
      voiced({ type: 'CALL_SIGNAL', value: 'make_call', phone_number: phoneNumber })
    }
    resolve();
  })
}

function end () {
  var activeCall = window.ABV.activeCall;
  var inCall = window.ABV.inCall;
  if (activeCall) {
    try {
      if (activeCall && activeCall.reject && !inCall
      ) {
        console.log('Rejected call');
        activeCall.reject();
      } else if (activeCall && activeCall.hangup && window.ABV.inCall) {
        console.log('Hangup call');
        activeCall.hangup();
      } else if (activeCall && activeCall.cancel) {
        console.log('Cancel call');
        activeCall.cancel();
      }
    } catch (e) {
      console.log('Hangup Error', e);
    };
  }
  window.ABV.activeCall = null;
  window.ABV.accept = false;
  window.ABV.inCall = false;
}

function accept () {
  var activeCall = window.ABV.activeCall;
  return new Promise((resolve, reject) => {
    if (window.ABV.accept) {
      return resolve();
    }

    const options = {
      sessionDescriptionHandlerOptions: {
        constraints: {
          audio: true,
          video: false
        }
      }
    };

    activeCall.accept(options);
    window.ABV.accept = true;
    window.ABV.activeCall = activeCall;

    resolve(window.ABV.activeCall);
  });
}

const toggleMute = function (mute) {
  var activeCall = window.ABV.activeCall;
  if (!activeCall) return;

  var pc = activeCall.session.sessionDescriptionHandler.peerConnection;
  if (pc.getSenders) {
    pc.getSenders().forEach(function (sender) {
      if (sender.track) {
        sender.track.enabled = !mute;
      }
    });
  } else {
    pc.getLocalStreams().forEach(function (stream) {
      stream.getAudioTracks().forEach(function (track) {
        track.enabled = !mute;
      });
      stream.getVideoTracks().forEach(function (track) {
        track.enabled = !mute;
      });
    });
  }
};

function mute () {
  var activeCall = window.ABV.activeCall;
  if (activeCall) {
    console.log('mute');
    // activeCall.mute({ audio: true, video: false });
    toggleMute(true);
  }
}

function unmute () {
  var activeCall = window.ABV.activeCall;
  if (activeCall) {
    console.log('unmute');
    // activeCall.unmute({ audio: true, video: false });
    toggleMute(false);
  }
}

function hold () {
  var activeCall = window.ABV.activeCall;
  if (activeCall) {
    activeCall.session.hold({
      inviteWithoutSdp: true,
      sessionDescriptionHandlerOptions: {
        audio: true,
        video: false
      }
    })
  }
}

function unhold() {
  var activeCall = window.ABV.activeCall;
  if (activeCall) {
    activeCall.session.unhold({
      sessionDescriptionHandlerOptions: {
        audio: true,
        video: false
      }
    });
  }
}

function dtmf(key) {
  var activeCall = window.ABV.activeCall;
  if (activeCall) {
    activeCall.dtmf(key);
  }
}

function register() {
  var activeCall = window.ABV.activeCall;
  if (activeCall) {
    activeCall.register();
  }
}

function unregister() {
  var activeCall = window.ABV.activeCall;
  if (activeCall) {
    activeCall.unregister();
  }
}

function transferBlind(agentNumber) {
  var activeCall = window.ABV.activeCall;
  if (activeCall) {
    var session = activeCall.session;
    session.refer(agentNumber);
  }
}

function resetMediaStream(stream) {
  if (!stream) stream = window.ABV.stream;
  if (stream) {
    stream.getTracks().forEach(function(track) {
      return track.stop();
    });
  }
}

export {
  initVoice,
  call,
  end,
  accept,
  mute,
  unmute,
  hold,
  unhold,
  dtmf,
  transferBlind,
  resetMediaStream,
  register,
  unregister
}
