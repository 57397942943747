import React from 'react';
import { useVoiceDispatch, useVoiceState } from '../../../../context/VoiceContext';
import { useCallMenuState, useCallMenuDispatch } from '../../../../context/CallMenuContext';
import { useLayoutState } from '../../../../context/LayoutContext';
import { withRestrictCall } from '../../../../services/withs';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export default function CallButton({ callButtonRef }) {
  const { t } = useTranslation();
  const voiced = useVoiceDispatch();
  const { voiceState } = useVoiceState();
  const callMenuState = useCallMenuState();
  const callMenuDispatch = useCallMenuDispatch();
  const { isDisableCall } = useLayoutState();
  const makeCall = async () => {
    const lastCallNumber = window.sessionStorage.getItem('lastCallNumber');
    if (callMenuState.phoneNumber === '') {
      callMenuDispatch({ type: 'SET_PHONE', number: lastCallNumber })
    } else {
      const call = withRestrictCall({ isDisableCall, t });
      if (call) {
        /* require register voice */
        if (voiceState !== 'registered') {
          toast.error(t('voiceNotReady'), {
            autoClose: 3000,
            hideProgressBar: true,
            position: toast.POSITION.TOP_CENTER
          });
          return;
        }
        if (callMenuState.phoneNumber !== '') {
          await call(callMenuState.phoneNumber, voiced)
        }
      }
      setTimeout(() => {
        callMenuDispatch({ type: 'SET_PHONE', number: '' });
      }, 100)
    }
  }

  const checkCall = () => {
    makeCall();
  }

  return (
    <div className="dialpad-wrapper__call">
      <div className="dialpad-wrapper_call-icon" onClick={checkCall} ref={callButtonRef} />
    </div>
  )
}
