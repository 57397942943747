import React from 'react'
import './index.scss'
import loadingImg from './img/loading.gif'

function OnLoading({ onShow }) {
  return (
    <div className='text-center'>
      {
        onShow ? <OnLoad /> : null
      }
    </div>
  )
}

const OnLoad = () => {
  return (
    <div className="on-loading">
      <div>
        <img alt="loading" src={loadingImg} />
      </div>
    </div>
  )
}
export default OnLoading;
