import React from 'react';
import MaterialIcon from '@material/react-material-icon'
import { withRouter } from 'react-router-dom';

const RecentButton = ({ history }) => {
  return (
    <div className="dialpad-wrapper__recent" onClick={() => { history.push('/app/history') }}>
      <MaterialIcon icon="history" />
    </div>
  )
}

export default withRouter(RecentButton)
