import { initVoice } from '../../services/call';
import Cookies from 'js-cookie';

export default function handleDispatchInit(state, action) {
  const config = action.config;
  if (!config) {
    Cookies.remove('credential', { domain: Cookies.get('rootDomain') });
    window.location.reload();
    return state;
  }
  const wsServers = [];
  if (process.env.REACT_APP_VOICE_DOMAIN_1) {
    wsServers.push({
      wsUri: process.env.REACT_APP_VOICE_DOMAIN_1,
      weight: 10
    });
  }

  if (process.env.REACT_APP_VOICE_DOMAIN_2) {
    wsServers.push({
      wsUri: process.env.REACT_APP_VOICE_DOMAIN_2,
      weight: 1
    });
  }
  const {
    displayName,
    uri,
    authorizationUser,
    password,
    stunServers
  } = config;
  const voiced = action.dispatch;

  initVoice({
    wsServers,
    displayName,
    uri,
    authorizationUser,
    password,
    stunServers
  }, voiced);

  return state;
}
