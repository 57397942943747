import HOutgoingCall from './HOutgoingCall';

var HPhone = function (config, UA, helper) {
  this.config = config;
  this.UA = UA;
  this.helper = helper;
}

HPhone.prototype.call = function(to, options) {
  var call = new HOutgoingCall(
    this.UA.invite(to, options),
    this.config,
    this.helper
  );
  return call;
}

export default HPhone
