import React from 'react';
import MaterialIcon from '@material/react-material-icon'
import Button from './Button';

export default function ShowDialpadButton({ isShowDtmf, callback }) {
  const activeClassName = () => {
    return isShowDtmf ? ' active' : '';
  };

  return (
    <Button onClick={callback} className={activeClassName()}>
      <MaterialIcon icon="dialpad" />
    </Button>
  )
}
