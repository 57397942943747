import React from 'react';
import API from '../apis';
// import Cookies from 'js-cookie';

var AuthenticateStateContext = React.createContext();
var AuthenticateDispatchContext = React.createContext();

function authenticateReducer(state, action) {
  console.log('Auth Context: ', action.type, action.value);
  switch (action.type) {
  case 'UPDATE_FIRST_TIME':
    return { ...state, firstTime: true };
  case 'LOGIN':
    return handleDispatchLogin(state, action);
  case 'SIGN_OUT':
    return handleDispatchSignout(state, action);
  default:
    throw new Error(`Unhandled action type: ${action.type}`);
  }
}

function handleDispatchLogin(state, action) {
  if (action.value === 'success') {
    return { ...state, isAuthenticated: true };
  } else if (action.value === 'failed') {
    return { ...state, isAuthenticated: false };
  } else {
    console.error('Unhandled dispatch LOGIN', action);
    return state;
  }
}

function handleDispatchSignout(state, action) {
  return { ...state, isAuthenticated: false };
}

function AuthenticateProvider({ children }) {
  var [state, dispatch] = React.useReducer(authenticateReducer, {
    isAuthenticated: false,
    firstTime: false
  });

  React.useEffect(initializeAuth.bind(null, state, dispatch), []);

  return (
    <AuthenticateStateContext.Provider value={state}>
      <AuthenticateDispatchContext.Provider value={dispatch}>
        {state.firstTime && children}
      </AuthenticateDispatchContext.Provider>
    </AuthenticateStateContext.Provider>
  );
}

function useAuthenticateState() {
  var context = React.useContext(AuthenticateStateContext);
  if (context === undefined) {
    throw new Error('useAuthenticateState must be used within a AuthenticateProvider');
  }
  return context;
}

function useAuthenticateDispatch() {
  var context = React.useContext(AuthenticateDispatchContext);
  if (context === undefined) {
    throw new Error('useAuthenticateDispatch must be used within a AuthenticateProvider');
  }
  return context;
}

export { AuthenticateProvider, useAuthenticateState, useAuthenticateDispatch, signOut };

function signOut(dispatch) {
}

function initializeAuth(state, dispatch) {
  async function firstInit() {
    let data;
    try {
      data = await API.User.getUserInfo();
    } catch (e) {
      console.error(e);
    }
    const user = data ? data.data : null;
    if (user && user.active) {
      dispatch({ type: 'LOGIN', value: 'success' });
    }
    dispatch({ type: 'UPDATE_FIRST_TIME' });
  }

  firstInit();
}
