const mediaConstraints = {
  audio: {
    optional: [
      { googEchoCancellation: true },
      { googNoiseReduction: true },
      { googHighpassFilter: true },
      { googNoiseSuppression: true },
      { googAutoGainControl: true },
      { googAudioMirroring: false },
      { googNoiseSuppression2: true },
      { googEchoCancellation2: true },
      { googAutoGainControl2: true },
      { googDucking: true }
    ]
  },
  video: false
};

const rtcConstraints = {
  optional: [
    { googCpuOveruseDetection: true },
    { googCpuOveruseEncodeUsage: true },
    { googCpuUnderuseThreshold: 55 },
    { googCpuOveruseThreshold: 85 },
    { googSkipEncodingUnusedStreams: true },
    { googScreencastMinBitrate: 400 },
    { googHighBitrate: true },
    { googVeryHighBitrate: true },
    { googHighStartBitrate: true },
    { googNumUnsignalledRecvStreams: 10 }
  ]
};

const outgoingOptions = {
  media: {
    constraints: {
      audio: true,
      video: false
    }
  },
  sessionDescriptionHandlerOptions: {
    constraints: {
      audio: true,
      video: false
    }
  }
}

export { mediaConstraints, rtcConstraints, outgoingOptions }
