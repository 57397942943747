import React from 'react';

export default function AudioControl() {
  return (
    <section id="media">
      <audio id="playerRemote" />
      <audio id="playerLocal" muted />
      <audio id="dtmf" autoPlay />
    </section>
  )
}
