import React from 'react';
import { end } from '../../../../services/call';
import { useVoiceDispatch } from '../../../../context/VoiceContext';

export default function RejectButton({ left, right }) {
  const voiceDispatch = useVoiceDispatch();
  const endCall = () => {
    voiceDispatch({
      type: 'CALL_SIGNAL',
      value: 'end_call'
    });
    end()
  }

  var callStyles = {};
  if (left) callStyles = { ...callStyles, left: left };
  if (right) callStyles = { ...callStyles, right: right };

  return (
    <div className="call end-call" onClick={endCall} style={callStyles}>
      <span />
    </div>
  )
}
