import React from 'react';
import _ from 'lodash';

export default function ActiveCallDuration({ timer }) {
  const formatDuration = (duration) => {
    if (_.isUndefined(duration)) return '';

    duration = parseFloat(duration);
    var seconds = duration % 60;
    var minutes = ((duration - seconds) / 60) % 60;
    var hours = Math.floor((duration - seconds) / 3600);
    var type = 'numbers';

    if (!seconds) seconds = 0;
    if (!minutes) minutes = 0;
    if (!hours) hours = 0;

    if (hours < 10 && type === 'numbers') {
      hours = '0' + hours;
    }
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    if (seconds < 10) {
      seconds = '0' + seconds;
    }

    if (hours === '00') {
      duration = minutes + ':' + seconds;
    } else {
      duration = hours + ':' + minutes + ':' + seconds;
    }

    return duration
  }
  return (
    <div className="ca-status">
      {timer ? formatDuration(timer) : 'Calling'}
    </div>
  )
}
