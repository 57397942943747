import React, { useState } from 'react';
import './index.scss';
import { useVoiceState } from '../../context/VoiceContext';

export default function VoiceEventLogger() {
  const [mode, setMode] = useState('');
  const { voiceLogs } = useVoiceState();
  const switchModeFull = () => {
    setMode('full');
  };

  const switchModeMini = () => {
    setMode('mini');
  };

  return (
    <>
      <div className={'scroller voice-event-logger-wrapper ' + (mode !== 'full' ? 'd-none' : '')}>
        <div className="voice-event-logger__panel">
          {
            voiceLogs.map(
              l =>
                <div key={l.id}>{l.value}</div>
            )
          }
        </div>
      </div>
      {
        mode === 'mini' &&
          <div className="voice-event-logger-mini" onClick={switchModeFull}>
            <i className="fa fa-angle-up" />
          </div>
      }
      {
        mode === 'full' &&
          <div className="voice-event-logger-mini" onClick={switchModeMini}>
            <i className="fa fa-angle-down" />
          </div>
      }
    </>
  )
}
