import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { transferBlind } from '../../../../../services/call';

export default function TransferScreen() {
  const { t } = useTranslation();
  const [transferNumber, setTransferNumber] = useState('');
  const [showError, setShowError] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, [])

  const handleTransferCall = () => {
    if (!transferNumber) return setShowError(true);
    transferBlind(transferNumber);
  };

  return (
    <div className="transfer-screen-wrapper">
      <div className="__main">
        <div className="__box-number">
          <span className="__title">{t('inputInternalNumber')}:</span>
          <input
            className="__input" type="text"
            value={transferNumber}
            ref={inputRef}
            onChange={(e) => setTransferNumber(e.target.value)}
            onKeyDown={(e) => { if (e.keyCode === 13) handleTransferCall() }}
          />
          {showError && <span className="__error">{t('thisFieldRequired')}</span>}
          <button className="__button" type="button" onClick={handleTransferCall}>{t('transferCall')}</button>
        </div>
      </div>
    </div>
  )
}
